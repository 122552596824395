import { Link } from "react-router-dom";
import style from "./header.module.scss";
import BrandLogo from "../../vectors/brand.component";
import { useContext, useState } from "react";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import { Close, CloseSharp } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { List } from "@mui/material";

export default function SideBarHeader() {
  const { hideSide, setHideSide } = useHideSideBar();
  const [brandLogoShade, setBrandLogoShade] = useState<string>("#FAFAFA");

  return (
    <div className={`${style["header"]} ${hideSide ? style["hidden"] : ""}`}>
      {!hideSide ? (
        <div className={style["header-container"]}>
          <div
            className={`${style["logo"]} ${hideSide ? style["hidden"] : ""}`}
          >
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to="/"
              onMouseEnter={() => setBrandLogoShade("#f0ddc8")}
              onMouseLeave={() => setBrandLogoShade("#FAFAFA")}
              title="accueil"
            >
              <BrandLogo
                className={`${
                  hideSide ? style["logo-menu-close"] : style["logo-menu"]
                }`}
                main={brandLogoShade}
              />
            </Link>
          </div>
          <div
            className={style["close-menu-container"]}
            onClick={() => setHideSide(!hideSide)}
          >
            <div>
              {!hideSide && (
                <div className={style["close-menu"]}>
                  <Close className={style["icon"]} />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={style["menu-icon"]}
          onClick={() => setHideSide(!hideSide)}
        >
          <MenuIcon className={style["icon"]} />
        </div>
      )}
    </div>
  );
}
