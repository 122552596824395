import { Link } from "react-router-dom";
import style from "./calendarToolBar.module.scss";
import {
  CalendarViewDay,
  CalendarViewWeek,
  ChevronLeft,
  ChevronRight,
  EventRepeat,
  FormatListBulleted,
} from "@mui/icons-material";
import { useRef, useState } from "react";

export default function CalendarToolBar({
  date,
  view,
  setView,
  setDate,
}: {
  date: Date;
  view: string;
  setView: (view: string) => void;
  setDate: (date: Date) => void;
}) {
  const dateRef = useRef<HTMLInputElement>(null);

  return (
    <div className={style["date-tools"]}>
      <div className={style["reset-date-container"]}>
        <div
          className={style["reset-date"]}
          onClick={() => {
            changeView("day");
            setDate(new Date());
          }}
        >
          <EventRepeat />
        </div>
      </div>
      <div className={style["date-changer"]}>
        <div
          className={style["navigate-button"]}
          onClick={() => handleChangeDate("previous", view)}
        >
          <ChevronLeft className={style["navigation-icon"]} />
        </div>
        <input
          type="date"
          value={date.toISOString().substring(0, 10)}
          onChange={handleOnChangeDate}
          className={style["date-input"]}
          ref={dateRef}
        />
        <span className={style["date-string"]} onClick={handlePickDate}>
          {getDateString()}
        </span>
        <div
          className={style["navigate-button"]}
          onClick={() => handleChangeDate("next", view)}
        >
          <ChevronRight className={style["navigation-icon"]} />
        </div>
      </div>
      <div className={style["date-view"]}>
        <div
          className={toggleStyleButton("day")}
          onClick={() => changeView("day")}
        >
          <CalendarViewDay />
        </div>
        <div
          className={toggleStyleButton("week")}
          onClick={() => changeView("week")}
        >
          <CalendarViewWeek />
        </div>
        <Link className={style["shipping-list"]} to={"./read"}>
          <FormatListBulleted />
          <span className={style["shipping-list-label"]}>
            Liste des livraisons
          </span>
        </Link>
        {/* <div
          className={toggleStyleButton("month")}
          onClick={() => changeView("month")}
        >
          <CalendarViewMonth />
        </div> */}
      </div>
    </div>
  );

  function changeView(viewButton: string) {
    setView(viewButton);
  }

  function handlePickDate() {
    dateRef?.current?.showPicker();
  }

  function handleOnChangeDate({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setDate(new Date(target.value));
  }

  function handleChangeDate(action: string, view: string) {
    let days = DAY_NUMBER.get(view) || 1;
    let newDate = new Date(date);
    if (action === "previous") {
      days = days * -1;
    }
    newDate.setDate(newDate.getDate() + days);
    setDate(newDate);
  }

  function toggleStyleButton(currentView: string) {
    if (currentView === view) {
      return style["date-view-button"] + " " + style["toggled"];
    }
    return style["date-view-button"];
  }

  function daysOfTheWeek(): { start: Date; end: Date } {
    let dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.
    let startOfWeek = new Date(date); // make a copy
    startOfWeek.setDate(date.getDate() - dayOfWeek + 1); // set to Monday of this week
    let endOfWeek = new Date(startOfWeek); // make a copy
    endOfWeek.setDate(startOfWeek.getDate() + 6); // set to Sunday of this week

    return { start: startOfWeek, end: endOfWeek };
  }

  function getDateString(): string {
    switch (view) {
      case "week":
        return (
          daysOfTheWeek().start.toLocaleDateString("fr-FR", {
            day: "2-digit",
          }) +
          " - " +
          daysOfTheWeek().end.toLocaleDateString("fr-FR", { day: "2-digit" }) +
          " " +
          date.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
          })
        );
      case "day":
        return date.toLocaleDateString("fr-FR", {
          weekday: "long",
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
      default:
        return date.toLocaleString("fr-FR", {
          month: "long",
          year: "numeric",
        });
    }
  }
}

const DAY_NUMBER = new Map<string, number>();
DAY_NUMBER.set("day", 1);
DAY_NUMBER.set("week", 7);
DAY_NUMBER.set("month", 30);
