import { IOperation } from "../types/accounting.types";
import { IContact, IProduct } from "../types/logistic.types";
import {
  IError,
  IExternalShipping,
  IPeriod,
  IShipping,
  IShippingPoint,
} from "../types/shipping.types";

export const INITIAL_CONTACT: IContact = {
  displayname: "",
  familyname: "",
  givenname: "",
  phone: "",
  email: "",
  organization: "",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
  country: "France",
  countryCode: "FR",
};

export const INITIAL_SHIPPING_ADDRESS: IShippingPoint = {
  displayname: "",
  familyname: "",
  givenname: "",
  phone: "",
  email: "",
  organization: "",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
  country: "France",
  countryCode: "FR",
  comments: "",
};

export const INITIAL_SHIPPING_PERIOD: IPeriod = {
  start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
};

export const INITIAL_SHIPPING_EXTERNAL: IExternalShipping = {
  reservationNumber: "",
  externalId: "",
  pickUpId: "",
};

const INITIAL_SHIPPING_ERROR: IError = {
  clientMissing: false,
  clientRefusal: false,
  other: "",
};

export const INITIAL_SHIPPING_OPERATION: IOperation = {
  description: "",
  operationType: "SHOES_AUTRE",
  comments: "",
  slug: "",
};

export const SHIPPING_MODE = new Map<string, number>();
SHIPPING_MODE.set("aucune livraison", 0);
SHIPPING_MODE.set("STANDARD_SHIPPING", 15);

export const TIMESLOT = ["08:00 - 11:00", "18:00 - 21:00"];

export const STATE_OPTIONS = [
  "PENDING",
  "TRANSITING",
  "DELIVERED",
  "CANCELED",
  "DRAFT",
];

export const PRINTMODE = [
  ["format ZPL", "ZPL"],
  ["PDF avec preuve de dépot", "PPR"],
  ["PDF sans preuve de dépot", "SPD"],
];

export const INITIAL_SHIPPING_PRODUCT: IProduct = {
  productGroup: "GENERIC_PRODUCT",
  operationGroup: "GENERIC_OPERATION",
  brand: "",
  comments: "",
  operationsList: [INITIAL_SHIPPING_OPERATION],
};

export const INITIAL_SHIPPING_STATE: IShipping = {
  id: "",
  _id: "id",
  contact: INITIAL_CONTACT,
  direction: "OUTGOING",
  sender: INITIAL_SHIPPING_ADDRESS,
  recipient: INITIAL_SHIPPING_ADDRESS,
  period: INITIAL_SHIPPING_PERIOD,
  shippingSourceType: "DIRECT",
  shippingDestinationType: "DIRECT",
  externalShipping: INITIAL_SHIPPING_EXTERNAL,
  shippingMethod: {
    shippingService: "INTERNAL",
    price: 15,
    freeShippingMinimumAmount: 150,
    businessDays: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    },
    businessHours: [{ start: "00:00", end: "00:00" }],
  },
  productsList: [],
  state: "PENDING",
  // takeOverDate: new Date(),
  imagesList: [],
  error: INITIAL_SHIPPING_ERROR,
};

export const GP_SHIPPING_ADDRESS: IShippingPoint = {
  displayname: "Henri de la Porte",
  familyname: "de la Porte",
  givenname: "Henri",
  organization: "Galoche et Patin",
  email: "atelier@galocheetpatin.fr",
  phone: "0768758164",
  address1: "41 rue Beaubourg",
  address2: "",
  city: "Paris",
  zipCode: "75003",
  country: "France",
  countryCode: "FR",
  comments: "",
};
