import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Detail from "../../components/splitPage/detail.component";

import style from "./index.module.scss";

import { useQuery } from "react-query";
import { authloader } from "..";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import {
  fetchPayedTrackersList,
  fetchUnfinishedTrackersList,
} from "../../requests/tracker";
import { queryObject } from "../../types/query.types";
import Item from "./listItem/item";

const OPTIONS_LIST = [
  "logistic.workshopIds",
  "contact.displayname",
  "contact.organization",
  "contact.phone",
  "contact.email",
];

const INITIAL_QUERY: queryObject = {
  field: "logistic.workshopIds",
  value: "",
  service: "",
  direction: "DESC",
  state: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "sort_field=updatedAt&sort_direction=desc",
  dateFrom: "",
  dateTo: "",
};

export default function Home() {
  //   const data = useLoaderData() as any;
  const [detailIndex, setDetailIndex] = useState<string>("");
  const [hideSide, setHideSide] = useState(true);

  useEffect(() => {
    authloader();
  }, []);

  function handleDetailClicked(event: React.SyntheticEvent, index: string) {
    event.preventDefault();
    setDetailIndex((prev) => (prev === index ? "" : index));
  }

  function handleLinkClicked() {
    setHideSide(true);
  }

  const {
    data: payedData,
    isFetching: payedIsFetching,
    error: payedError,
    refetch: payedRefetch,
  } = useQuery("payedTrackersList", () =>
    fetchPayedTrackersList(formatedQuery())
  );

  const {
    data: unfinishedData,
    isFetching: unfinishedIsFetching,
    error: unfinishedError,
    refetch: unfinishedRefetch,
  } = useQuery("unfinishedTrackers", () =>
    fetchUnfinishedTrackersList(formatedQuery())
  );
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);

  useEffect(() => {
    payedRefetch();
    unfinishedRefetch();
  }, [query]);

  return (
    <div className={style["overview-index-container"]}>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={payedData?.totalCount}
          title="A réparer"
        />
        <div className={style["header"]}>
          <div className={style["header-element-container"]}>Nom complet</div>
          <div className={style["header-element-container"]}>Estimation</div>
          <div className={style["header-element-container"]}>Panier</div>
          <div className={style["header-element-container"]}>Collecte</div>
          <div className={style["header-element-container"]}>Logistique</div>
          <div className={style["header-element-container"]}>Devis</div>
          <div className={style["header-element-container"]}>Livraison</div>
          <div className={style["header-element-container"]}>Général</div>
        </div>
        {payedData &&
          payedData.trackersList &&
          payedData.trackersList.map((value: any, index: number) => {
            return <Item data={value} key={index} />;
          })}
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={unfinishedData?.totalCount || 0}
          title="Livraison < 48h"
        />
        <div className={style["header"]}>
          <div className={style["header-element-container"]}>Nom complet</div>
          <div className={style["header-element-container"]}>Estimation</div>
          <div className={style["header-element-container"]}>Panier</div>
          <div className={style["header-element-container"]}>Collecte</div>
          <div className={style["header-element-container"]}>Logistique</div>
          <div className={style["header-element-container"]}>Devis</div>
          <div className={style["header-element-container"]}>Livraison</div>
          <div className={style["header-element-container"]}>Général</div>
        </div>
        {payedData &&
          unfinishedData &&
          unfinishedData.trackersList &&
          unfinishedData.trackersList.map((value: any, index: number) => {
            return <Item data={value} key={index} />;
          })}
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `&${query.sort}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&state=${query.state}`;
    }

    if (!(query.dateFrom === "" && query.dateTo === "")) {
      formatedQuery =
        formatedQuery +
        `&createdAt=` +
        query.dateFrom +
        `&createdAt=` +
        query.dateTo;
    }
    return formatedQuery;
  }
}
