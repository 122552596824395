import { FormEvent, useEffect, useMemo, useRef, useState } from "react";
import style from "./create.module.scss";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import {
  ICrossSellQuote,
  IDiscount,
  IQuote,
} from "../../../types/accounting.types";
import { IContact } from "../../../types/logistic.types";
import { IProduct } from "../../../types/logistic.types";
import InputContainer from "../../../components/forms/inputs/inputContainer/inputContainer";
import { INITIALE_QUOTE, QUOTE_STATES } from "../../../utils/accounting.init";
import { fetchDiscountList } from "../../../requests/discount";
import { useLoaderData, useNavigate } from "react-router-dom";
import { addQuote, updateQuote } from "../../../requests/quote";
import { scrollToElement } from "../../../utils/utils";
import { IValidationError } from "../../../types";
import { authloader } from "../..";
import { INITIAL_CONTACT } from "../../../utils/shipping.init";
import SelectState from "../../shipping/create/SelectState/SelectState";
import Select from "../../../components/Select/Select";
import Input from "../../../components/inputs/Input/Input";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import Button from "../../../components/Button/Button";
import ConcurrentTasksTemplate from "../../../components/concurrentTasksTemplate/ConcurrentTasksTemplate";
import CheckboxItem from "../../../components/checkboxItem/CheckboxItem";
import AddCrossSell from "../../../components/forms/crossSellForm/addCrossSell";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { Close, Send } from "@mui/icons-material";
import ProductSection from "../../../components/forms/productForm/ProductSection";
import OverviewButton from "../../../components/OverviewButton/OverviewButton";

export async function loader({ request }: { request: Request }) {
  try {
    const discountList = await fetchDiscountList();
    authloader();
    return discountList;
  } catch (error) {
    console.error(error);
  }
}

interface CreateProps {
  data?: IQuote;
  refetch?: () => void;
}

export default function Create({ data, refetch }: CreateProps) {
  const [formValues, setFormValues] = useState<IQuote>(
    data ? { ...INITIALE_QUOTE, ...data } : { ...INITIALE_QUOTE }
  );
  const [scrollTo, setScrollTo] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );
  const { discounts } = useLoaderData() as {
    discounts: IDiscount[];
    totalCount: number;
  };
  const [updateLogistic, setUpdateLogistic] = useState<boolean>(true);
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const navigate = useNavigate();
  const CAN_EDIT = useMemo(() => {
    if (!data) {
      return true;
    }
    return data.state !== "QUOTE_SENT" && data.state !== "QUOTE_VALIDATED";
  }, [data]);

  useEffect(() => {
    if (data) {
      setFormValues({ ...INITIALE_QUOTE, ...data })
    }
  }, [data])

  useEffect(() => {
    handleScroll();
  }, [scrollTo]);

  useEffect(() => {
    calculateTotalPrice();
  }, [
    formValues.operationsTotal,
    formValues.salesTotal,
    formValues.discountTotal,
    formValues.shipping?.price,
  ]);

  useEffect(() => {
    if (!formValues.crossSellItems) {
      return;
    }
    if (formValues.crossSellItems.length === 0) {
      setFormValues((prev) => ({ ...prev, crossSellItems: undefined }));
    }
  }, [formValues.crossSellItems]);

  return (
    <div className={style["quote"]}>
      <div ref={ref} className={style["title"]}>
        <span className={style["title-container"]}>
          {data ? "Modifier devis" : "Nouveau devis"}
          <OverviewButton trackId={formValues.trackId} />
        </span>
        <div className={style["top-right"]}>
          <SelectState
            label="Status du devis"
            state={formValues.state ?? "QUOTE_PENDING"}
            stateOption={QUOTE_STATES}
            setState={(state) => {
              setFormValues({ ...formValues, state });
            }}
          />
          {formValues?.contact?.email && formValues.trackId && (
            <Button
              isLoading={isLoading}
              onClick={handleSendQuoteValidationPendingEmail}
            >
              Envoyer le devis <Send className={style["send-icon"]} />
            </Button>
          )}
        </div>
      </div>
      <div className={style["form"]}>
        <SectionFormTemplate title="Contact">
          <ContactForm
            contact={formValues.contact || INITIAL_CONTACT}
            setContact={handleContactChange}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Délai (jours)">
          <div className={style["input-line"]}>
            <Input
              label="Délai minimum"
              name={"minimumDurationDays"}
              type={"text"}
              inputMode="numeric"
              min={1}
              value={formValues?.minimumDurationDays}
              onChange={handleChange}
            />
            <Input
              label="Délai maximum"
              name={"maximumDurationDays"}
              type={"text"}
              inputMode="numeric"
              min={0}
              value={formValues?.maximumDurationDays}
              onChange={handleChange}
            />
          </div>
        </SectionFormTemplate>
        <SectionFormTemplate title="Prix de la livraison (€)">
          <Input
            name={"shipping.price"}
            type={"text"}
            inputMode="numeric"
            min={1}
            value={formValues?.shipping?.price}
            onChange={handleShippingPriceChange}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Code promo">
          <Select
            className={style["discount-select"]}
            placeholder="Sélectionnez un code promo..."
            value={formValues?.discount?.code || ""}
            setValue={handleDiscountChange}
            optionsList={discounts.map((value: IDiscount) => value.code)}
          />
          {formValues.discount && formValues.discount.code !== "AUCUN" && (
            <div
              className={style["remove-discount"]}
              onClick={() => handleDiscountChange("AUCUN")}
            >
              <Close />
            </div>
          )}
        </SectionFormTemplate>
        {data && (
          <SectionFormTemplate title="Dates d'envoi/réponse">
            <div className={style["input-line"]}>
              <InputContainer className={style["input-container"]}>
                <label>Date d'envoi</label>
                <input
                  name={"sentAt"}
                  type={"date"}
                  value={
                    formValues.sentAt
                      ? new Date(formValues.sentAt)
                          .toISOString()
                          .substring(0, 10)
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </InputContainer>
              <InputContainer className={style["input-container"]}>
                <label>Date de réponse</label>
                <input
                  name={"repliedAt"}
                  type={"date"}
                  value={
                    formValues.repliedAt
                      ? new Date(formValues.repliedAt)
                          .toISOString()
                          .substring(0, 10)
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </InputContainer>
            </div>
          </SectionFormTemplate>
        )}
        <SectionFormTemplate title="Commentaire">
          <textarea
            className={style["comment"]}
            value={formValues.comments}
            onChange={({ target }) =>
              setFormValues((prev) => ({ ...prev, comments: target.value }))
            }
          />
        </SectionFormTemplate>
        <hr className={style["separator"]} />
        <ProductSection
          productsList={formValues.productsList || []}
          setProductsList={handleProductChange}
          canEdit={CAN_EDIT}
        />
        <SectionFormTemplate title="Articles Cross-selling">
          {/* {CAN_EDIT && ( */}
          <AddCrossSell
            handleCrossSellChange={handleCrossSellChange}
            crossSellItems={formValues.crossSellItems}
            handleDeleteCrossSellItem={handleDeleteCrossSellItem}
          />
          {/* )} */}
        </SectionFormTemplate>
        <hr className={style["separator"]} />
        <div className={style["input-line"]}>
          <InputContainer className={style["input-container"]}>
            <label>Total code promo</label>
            <span className={style["price-value"]}>{`${
              formValues.discount?.value || 0
            } ${formValues.discount?.isRate ? "%" : "€"}`}</span>
          </InputContainer>
          <InputContainer className={style["input-container"]}>
            <label>Total opérations</label>
            <span className={style["price-value"]}>{`${
              formValues.operationsTotal || 0
            } €`}</span>
          </InputContainer>
          <InputContainer className={style["input-container"]}>
            <label>Total Cross-selling</label>
            <span className={style["price-value"]}>{`${
              formValues.salesTotal || 0
            } €`}</span>
          </InputContainer>
          <InputContainer className={style["input-container"]}>
            <label>Total</label>
            <span className={style["price-value"]}>{`${
              formValues.total || 0
            } €`}</span>
          </InputContainer>
        </div>
        <div>
          {validationError.find(
            (value: IValidationError) => value.field === "productsList"
          ) && (
            <div className={style["error-message"]}>
              * veuillez ajouter au moins un article.
            </div>
          )}
        </div>
        <ConcurrentTasksTemplate>
          <CheckboxItem
            checked={updateLogistic}
            setChecked={setUpdateLogistic}
            title="Mettre à jour le document logistique associé"
          />
          <CheckboxItem
            checked={sendEmail}
            setChecked={setSendEmail}
            title="Envoyer le devis"
          />
        </ConcurrentTasksTemplate>
        <div
          className={style["submit-button-container"]}
          onClick={handleConfirmSubmit}
        >
          <Button type={"submit"}>{data ? "Mettre à jour" : "Créer"}</Button>
        </div>
      </div>
    </div>
  );

  async function handleConfirmSubmit() {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={`Voulez-vous ${data ? "mettre à jour" : "créer"} ce devis ?`}
        element={
          <>
            {(sendEmail || updateLogistic) && (
              <ul style={{ fontSize: "14px" }}>
                Ceci entrainera les actions suivantes:
                {sendEmail && <li>Envoi du devis par email</li>}
                {updateLogistic && <li>Mise à jour du document logistique</li>}
              </ul>
            )}
          </>
        }
        confirmText="Oui"
        cancelText="Non"
        onConfirm={handleSubmitForm}
      />
    );
  }

  async function handleSendQuoteValidationPendingEmail() {
    if (!formValues?.contact?.email) {
      return;
    }
    try {
      setIsLoading(true);
      await updateQuote({ ...formValues, state: "QUOTE_SENT" }, false, true);
      setFormValues((prev) => ({ ...prev, state: "QUOTE_SENT" }));
      toast.success(`Le devis a été envoyé avec succès`, TOAST_SUCCESS_OPTIONS);
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de l'envoi du devis.",
        TOAST_ERROR_OPTIONS
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmitForm() {
    try {
      const response =
        data && data.id
          ? await updateQuote(formValues, updateLogistic, sendEmail)
          : await addQuote(formValues, sendEmail);
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        setScrollTo(!scrollTo);
        return;
      }
      toast.success(
        `Le devis a été ${data ? "mis à jour" : "crée"}.`,
        TOAST_SUCCESS_OPTIONS
      );
      refetch && refetch();
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleProductChange(productsList: IProduct[]) {
    const newFormValues = { ...formValues };
    newFormValues["productsList"] = productsList;
    newFormValues.operationsTotal = productsList.reduce(
      (acc, product: IProduct) => acc + (product.totalPrice ?? 0),
      0
    );
    console.debug(newFormValues.operationsTotal);
    setFormValues(newFormValues);
  }

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({ ...prev, contact: contact }));
  }

  function handleCrossSellChange(crossSell: ICrossSellQuote[]) {
    const salesTotal =
      crossSell.reduce(
        (acc, cs) => acc + (cs.price || 0) * cs.cartQuantity,
        0
      ) / 100;
    console.debug(salesTotal, crossSell);
    setFormValues((prev) => ({
      ...prev,
      crossSellItems: crossSell,
      salesTotal,
    }));
  }

  function handleDeleteCrossSellItem(index: number) {
    if (!formValues.crossSellItems) {
      return;
    }
    const salesTotal =
      formValues.crossSellItems
        ?.filter((_, i) => i !== index)
        .reduce((acc, cs) => acc + (cs.price || 0) * cs.cartQuantity, 0) / 100;
    setFormValues((prev) => ({
      ...prev,
      crossSellItems: formValues.crossSellItems?.filter((_, i) => i !== index),
      salesTotal,
    }));
  }

  function handleChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }) {
    setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
  }

  function handleShippingPriceChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }) {
    let price = parseInt(target.value);
    if (isNaN(price)) {
      price = 0;
    }
    setFormValues((prev) => {
      let shipping = prev.shipping || {
        freeShippingMinimumAmount: price,
        price: price,
        shippingService: "INTERNAL",
      };
      return {
        ...prev,
        shipping: {
          ...shipping,
          price: price,
          freeShippingMinimumAmount: price,
        },
      };
    });
  }

  function handleDateChange({
    target,
  }: {
    target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
  }) {
    setFormValues((prev) => ({
      ...prev,
      [target.name]: new Date(target.value),
    }));
  }

  function handleDiscountChange(value: string) {
    const found = discounts.find(
      (discount: IDiscount) => discount.code === value
    );
    setFormValues((prev) => ({
      ...prev,
      discount: found || null,
      discountTotal: calculateDiscountTotal({
        ...prev,
        discount: found || null,
      }),
    }));
  }

  function calculateDiscountTotal(quote: IQuote) {
    if (!quote.discount || !quote.discount.value) return 0;
    if (!quote.discount.isRate) {
      return quote.discount.value;
    }
    return ((quote.operationsTotal || 0) * quote.discount.value) / 100;
  }

  function calculateTotalPrice() {
    const newFormValues = { ...formValues };
    const total =
      (formValues.operationsTotal ?? 0) +
      (formValues.salesTotal ?? 0) +
      (formValues.shipping?.price ?? 0) -
      (formValues.discountTotal ?? 0);

    newFormValues.total = total >= 0 ? total : 0;

    setFormValues(newFormValues);
  }

  function handleScroll() {
    if (
      validationError.some((e) =>
        [
          "contact.email",
          "contact.phone",
          "contact.familyname",
          "contact.displayname",
        ].some((str) => e.field.includes(str))
      )
    ) {
      scrollToElement(ref.current, 1000);
    }
  }
}
