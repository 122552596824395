import { Delete } from "@mui/icons-material";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { IInvoicePro } from "../../../types/invoicePro.types";
import style from "./item.module.scss";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { useModalComponent } from "../../../context/modalComponent.context";
import InvoiceProModal from "../../../components/modals/invoiceProModal/invoiceProModal";

export default function Item({
  invoice,
  onDelete,
  handleGenerate,
  handleFinalize,
  handleToPayed,
  index,
}: {
  invoice: IInvoicePro;
  onDelete: (invoice?: IInvoicePro) => void;
  handleGenerate: (id?: string) => void;
  handleFinalize: (id?: string) => void;
  handleToPayed: (invoice?: IInvoicePro) => void;
  index: number;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  let TOOLS_LIST: IMoreToolsOptions[] = [];

  let invoiceProPennylane = "QUOTE_TO_GENERATE";
  if (invoice.publicUrl && invoice.isDraft === true) {
    invoiceProPennylane = "QUOTE_DRAFT";
  }
  if (invoice.publicUrl && invoice.isDraft === false) {
    invoiceProPennylane = "QUOTE_VALIDATED";
  }
  TOOLS_LIST = getToolList(TOOLS_LIST, invoice, invoiceProPennylane);

  let totalHt = "0.00";
  let totalTtc = "0.00";
  if (invoice.total) {
    let totalHtCent = invoice.total / 1.2;
    let totalTtcCent = invoice.total;
    totalHt = (totalHtCent / 100).toFixed(2).toString();
    totalTtc = (totalTtcCent / 100).toFixed(2).toString();
  }
  return (
    <div className={style["item"]}>
      <div className={`${style["item-element"]} ${style["status-box"]}`}>
        <span
          className={style["draft-status"]}
          style={{
            color: `${getStatusColor(invoiceProPennylane)}`,
            border: `2px solid ${getStatusColor(invoiceProPennylane)}`,
          }}
        >
          {translate(invoiceProPennylane)}
        </span>
      </div>
      <div className={`${style["item-element"]} ${style["item-coord"]}`}>
        <span>
          {invoice.contact?.organization}
          <br />
          {invoice.contact?.email}
        </span>
      </div>
      <div className={`${style["item-element"]} ${style["item-date"]}`}>
        <span>
          {invoice.date
            ? new Date(invoice.date).toLocaleString("fr-FR").substring(0, 10)
            : "indisponible"}
        </span>
      </div>
      <div className={`${style["item-element"]} ${style["item-date"]}`}>
        <span>{invoice.quotesIds.length}</span>
      </div>
      <div className={`${style["item-element"]} ${style["item-date"]}`}>
        <span>{(totalHt || "0.00") + "€"}</span>
      </div>
      <div className={`${style["item-element"]} ${style["item-date"]}`}>
        <span>{(totalTtc || "0.00") + "€"}</span>
      </div>
      <div
        className={`${style["item-element"]} ${style["status-box"]}`}
        onClick={handleItemClick}
      >
        <span
          className={style["status"]}
          style={{ backgroundColor: `${getStatusColor(invoice.state)}` }}
        >
          {translate(invoice.state)}
        </span>
      </div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <MoreTools options={TOOLS_LIST} zIndex={index} />
        </ProtectedComponent>
      </div>
    </div>
  );

  function handleItemClick() {
    if (!invoice.id || !invoice.shopId) {
      return;
    }
    setModalChildren(
      <InvoiceProModal invoiceId={invoice.id} shopId={invoice.shopId} />
    );
    setModalIsOpen(true);
  }

  function handleBill(url?: string) {
    if (url) {
      window.open(url, "_blank");
    }
  }

  function getToolList(
    TOOLS_LIST: IMoreToolsOptions[],
    invoice: IInvoicePro,
    invoiceProPennylane: string
  ) {
    if (invoiceProPennylane == "QUOTE_TO_GENERATE") {
      TOOLS_LIST.push({
        onClick: () => handleGenerate(invoice.id),
        icon: <AddCircleOutlineIcon />,
        name: "Générer la facture",
      });
    }
    if (invoiceProPennylane == "QUOTE_DRAFT") {
      TOOLS_LIST.push({
        onClick: () => handleFinalize(invoice.id),
        icon: <ArrowCircleUpIcon />,
        name: "Finaliser la facture",
      });
      TOOLS_LIST.push({
        onClick: () => handleBill(invoice.fileUrl),
        icon: <ArrowCircleDownIcon />,
        name: "Télécharger la facture",
      });
    }
    if (invoiceProPennylane == "QUOTE_VALIDATED") {
      TOOLS_LIST.push({
        onClick: () => handleBill(invoice.fileUrl),
        icon: <ArrowCircleDownIcon />,
        name: "Télécharger la facture",
      });
    }
    if (
      invoice.state === "TO_BE_PAYED" &&
      invoiceProPennylane == "QUOTE_VALIDATED"
    ) {
      TOOLS_LIST.push({
        onClick: () => handleToPayed(invoice),
        icon: <CheckCircleOutlineIcon />,
        name: "Marquer comme payée",
      });
    }
    TOOLS_LIST.push({
      onClick: () => onDelete(invoice),
      icon: <Delete />,
      name: "Supprimer",
    });
    return TOOLS_LIST;
  }
}
