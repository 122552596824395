import { createContext, useContext, useState } from "react";

interface HideSideBarContextProps {
  hideSide: boolean;
  setHideSide: (value: boolean) => void;
}

const defaultContextProps: HideSideBarContextProps = {
  hideSide: false,
  setHideSide: (value: boolean) => {},
};

const HideSideBarContext =
  createContext<HideSideBarContextProps>(defaultContextProps);

export default function HideSideBarProvider(props: any) {
  const [hideSide, setHideSide] = useState<boolean>(false);
  return (
    <HideSideBarContext.Provider value={{ hideSide, setHideSide }}>
      {props.children}
    </HideSideBarContext.Provider>
  );
}

export function useHideSideBar() {
  const context = useContext(HideSideBarContext);

  if (context === undefined) {
    throw new Error("useHideSideBar must be used within a HideSideBarProvider");
  }
  return context;
}
