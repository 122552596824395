import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import style from "./subMenuDispatch.module.scss";
import SubMenuDispatch from "./subMenuDispatch.component";
import { useHideSideBar } from "../../../context/hideSideBar.context";

export default function SideBarMenu({
  handleLinkClicked,
  to,
  title,
}: {
  handleLinkClicked: () => void;
  to?: string;
  title: string;
}) {
  const { hideSide, setHideSide } = useHideSideBar();
  const [showSubMenu, setShowSubMenu] = useState<Boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSubMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  function handleClick() {
    handleLinkClicked();
    window.scrollTo(0, 0);
  }

  return (
    <div
      className={style["side-menu"]}
      ref={ref}
      onClick={() => setShowSubMenu(!showSubMenu)}
    >
      <div
        // className={style["title"]}
        className={`${style["title"]} ${
          hideSide ? style["hidden-sub-menu-title"] : ""
        }`}
      >
        {to ? (
          <Link onClick={handleClick} to={to}>
            {title}
          </Link>
        ) : (
          `${title}`
        )}
      </div>
      {/* {showSubMenu && (
        <SubMenuDispatch name={title} handleLinkClicked={handleLinkClicked} />
      )} */}
    </div>
  );
}
