import Create from "../create/Create";
import { useLoaderData, useParams } from "react-router-dom";
import {
  fetchQuoteFromTrackerId,
  loadShipping,
} from "../../../requests/tracker";
import { IShipping } from "../../../types/shipping.types";
import { authloader } from "../..";
import { GP_SHIPPING_ADDRESS } from "../../../utils/shipping.init";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

export async function loader({ params }: { params: any }) {
  try {
    const { shipping } = await loadShipping({ params });
    authloader();
    return shipping;
  } catch (error: any) {
    throw error;
  }
}

export default function Generate() {
  const { trackerId } = useParams();
  const { data: quote, isLoading } = useQuery(
    ["quote", trackerId],
    () => fetchQuoteFromTrackerId(trackerId || ""),
    { enabled: !!trackerId }
  );

  const data: IShipping | null = useLoaderData() as IShipping;
  if (!data.period) {
    data.period = { start: new Date(), end: new Date() };
  }
  if (data.direction === "INCOMING" && !data.recipient) {
    data.recipient = GP_SHIPPING_ADDRESS;
  } else if (data.direction === "OUTGOING" && !data.sender) {
    data.sender = GP_SHIPPING_ADDRESS;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!data || !quote) {
    return <></>;
  }

  return (
    <Create
      shipping={data}
      dueDate={getDateAfterDays(quote.maximumDurationDays)}
    />
  );

  function getDateAfterDays(days?: number): Date | undefined {
    if (!days) {
      return undefined;
    }
    let currentDate = new Date();
    let addedDays = 0;
    while (addedDays < days) {
      currentDate.setDate(currentDate.getDate() + 1);
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        addedDays++;
      }
    }
    return currentDate;
  }
}
