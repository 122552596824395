import { useFrontLabel } from "./useFrontLabel";
import styles from "./frontLabel.module.scss";
import { usePrinter } from "../../../context/printer.context";
import PrintButton from "../../../components/PrintButton/PrintButton";
import PrintOperationsChecklist from "../../../components/PrintOperationsCheckList/PrintOperationsChecklist";

interface FrontLabelProps {
  operations: string[][];
  workshopId: string;
  dueDate: Date | null;
}

export default function FrontLabel({
  operations,
  workshopId,
  dueDate,
}: FrontLabelProps) {
  const frontCanvas = useFrontLabel(
    operations,
    workshopId,
    dueDate
      ? "à faire avant le: " + new Date(dueDate).toLocaleDateString("fr-FR")
      : "-"
  );

  const { onPrint, printProgress, printState, connectionState } = usePrinter();
  console.debug(operations);
  return (
    <div className={styles["front-label"]}>
      <div className={styles["front-label-container"]}>
        <h3>Étiquette recto</h3>
        <canvas ref={frontCanvas} style={{ border: "1px solid black" }} />
      </div>
      {connectionState === "connected" && (
        <PrintButton
          handleOnPrint={handleOnPrint}
          printProgress={printProgress}
          printState={printState}
        />
      )}
    </div>
  );

  async function handleOnPrint() {
    onPrint({ canvas: frontCanvas.current });
  }
}
