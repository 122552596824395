import translate from "../../translation";
import getStatusColor from "../../translation/statusColor";
import styles from "./state.module.scss";

interface StateProps {
  state?: string;
}

export default function State({ state = "indisponible" }: StateProps) {
  const color = getStatusColor(state);
  return (
    <div
      className={styles["state-container"]}
      style={{ borderColor: color, color: color }}
    >
      {translate(state)}
    </div>
  );
}
