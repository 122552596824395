import { Delete, Edit, Key } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ILicense } from "../../../types/license.types";
import style from "./item.module.scss";
import { useMutation } from "react-query";
import { generateLicenseKey } from "../../../requests/licenses";
import { useContext } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import LicenseKeysModal from "../../../components/modals/licenseKeysModal/licenseKeysModal";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";

interface ItemProps {
  data: ILicense;
  onDelete: (id: string) => void;
}

export default function Item({ data, onDelete }: ItemProps) {
  const { setModalChildren, setModalIsOpen } = useModalComponent();

  const generateKeyMutation = useMutation(generateLicenseKey, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.redirections) {
        setModalChildren(<LicenseKeysModal redirections={data.redirections} />);
        setModalIsOpen(true);
      }
    },
    onError: (error) => {
      console.log(error);
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  return (
    <div className={style["item"]}>
      <div className={style["username"]}>
        {data.contact?.organization} - {data.contact?.displayname}
      </div>
      <div className={style["username"]}>{(data.shopsList || []).length}</div>
      <div className={style["username"]}>
        {(data.monthlyPrice || 0) +
          (data.shopsList || []).reduce(
            (prev, cur) => prev + (cur.monthlyPrice || 0),
            0
          )}{" "}
        €
      </div>
      <div className={style["tools"]}>
        <div className={style["icon"]} title={"modifier"}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["icon"]}
            to={`./edit/${data.id}`}
          >
            <Edit />
          </Link>
        </div>
        <div
          className={style["icon"]}
          onClick={handleGenerateKey}
          title={"Clés"}
        >
          <Key />
        </div>
        <div
          className={style["icon"]}
          onClick={handleDelete}
          title={"supprimer"}
        >
          <Delete />
        </div>
      </div>
    </div>
  );

  function handleDelete() {
    if (
      window.confirm(
        "Êtes-vous sûre de vouloir supprimer définitivement cette license ?"
      )
    ) {
      data.id && onDelete(data.id);
    }
  }

  function handleGenerateKey() {
    if (data?.id) {
      generateKeyMutation.mutate({ id: data.id });
    }
  }
}
