import {
  Close,
  LocalOfferOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";
import style from "./logisticMiniature.module.scss";
import { ILogistic } from "../../../types/tracker.types";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { useModalComponent } from "../../../context/modalComponent.context";
import LogisticModal from "../../modals/logisticModal/logisticModal";

interface LogisticMiniatureProps {
  dueDate?: Date;
  trackerId: string;
  data: ILogistic;
  requiredActions: boolean;
  refetch: () => void;
}

export default function LogisticMiniature({
  dueDate,
  trackerId,
  data,
  requiredActions,
  refetch,
}: LogisticMiniatureProps) {
  const [requiredActionsBubble, setRequiredActionsBubble] =
    useState<boolean>(requiredActions);
  const navigate = useNavigate();

  const { modalIsOpen, setModalIsOpen, setModalChildren, setConfirmClose } =
    useModalComponent();

  useEffect(() => {
    if (!modalIsOpen) {
      setRequiredActionsBubble(requiredActions);
    }
  }, [requiredActions, modalIsOpen]);

  useEffect(() => {
    refetch();
  }, [modalIsOpen]);

  function openLogisticModal() {
    if (data && data.documentId.toString() !== "") {
      setRequiredActionsBubble(false);
      setModalIsOpen(true);
      setConfirmClose(true);
      setModalChildren(
        <LogisticModal
          id={data.documentId}
          requiredActions={requiredActions}
          dueDate={dueDate}
        />
      );
    }
  }

  return (
    <div className={style["logistic-miniature"]}>
      {requiredActionsBubble && data && (
        <div className={style["required-action-notification"]}>
          <div className={style["required-action-notification-container"]}>
            <div
              className={style["required-action-notification-message"]}
              onClick={openLogisticModal}
            >
              Actions requises
              <WarningAmberOutlined className={style["warning-icon"]} />
            </div>
            <div
              className={style["notification-close-button"]}
              onClick={() => setRequiredActionsBubble(false)}
            >
              <Close />
            </div>
          </div>
        </div>
      )}
      <div
        className={style["logistic-miniature-container"]}
        onClick={openLogisticModal}
      >
        {data && data.documentId.toString() !== "" ? (
          <div>
            <div className={style["top-container"]}>
              <div className={style["header-container"]}>
                <div className={style["title"]}>
                  Logistique{" "}
                  {!requiredActionsBubble && requiredActions && (
                    <WarningAmberOutlined className={style["warning-icon"]} />
                  )}
                </div>
                <div
                  className={style["state"]}
                  style={{ backgroundColor: `${getStatusColor(data.state)}` }}
                >
                  {translate(data.state)}
                </div>
              </div>
              <div className={style["body-container"]}>
                <span>Articles {`(${data.workshopIdsList?.length || 0})`}</span>
                <div className={style["workshopId-list"]}>
                  {data?.workshopIdsList.map(
                    (workshopId: string, index: number) => {
                      if (index < 2) {
                        return (
                          <div key={index} className={style["workshopId"]}>
                            {workshopId} <LocalOfferOutlined />
                          </div>
                        );
                      }
                    }
                  )}
                  {data.workshopIdsList?.length > 3 && (
                    <div className={style["three-dots"]}>
                      <span className={style["dot"]}></span>
                      <span className={style["dot"]}></span>
                      <span className={style["dot"]}></span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={style["add-new-container"]}>
            <button className={style["add-new"]} onClick={generateLogistic}>
              Générer un document logistique
            </button>
          </div>
        )}
      </div>
    </div>
  );

  function generateLogistic() {
    navigate(`/quick-access/in-store-drop-off/generate/${trackerId}`);
  }
}
