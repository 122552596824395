import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchCalendarData } from "../../requests/shipping";
import style from "./index.module.scss";
import Calendar from "./calendar/calendar";
import ShippingModalSelect from "./calendar/shippingModal/shippingModalSelect";
import CalendarToolBar from "./calendar/calendarToolBar";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";

export interface IOpenModal {
  id: string;
  date: Date;
  isExternal: boolean;
  isAllDay: boolean;
}

const INITIAL_STATE = {
  id: "-1",
  date: new Date(),
  isExternal: false,
  isAllDay: false,
};

const INITIAL_QUERY = `period.start=${startOfDay(
  new Date()
).toISOString()}&period.start=${endOfDay(new Date()).toISOString()}`;

export default function Shippings() {
  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<string>("day");
  const [openModal, setOpenModal] = useState<IOpenModal>(INITIAL_STATE);
  const [query, setQuery] = useState<string>(INITIAL_QUERY);
  const { data, isLoading } = useQuery(
    ["shippingCalendarData", query, openModal, view],
    () => fetchCalendarData(query, view),
    { refetchOnWindowFocus: true, keepPreviousData: true }
  );

  useEffect(() => {
    let [start, end]: [string, string] = ["", ""];

    if (view === "month") {
      start = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
      end = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
    } else if (view === "day") {
      start = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        1
      ).toISOString();
      end = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        22
      ).toISOString();
    } else {
      start = daysOfTheWeek(date).start.toISOString();
      end = daysOfTheWeek(date).end.toISOString();
    }

    const newQuery: string = `period.start=${start}&period.start=${end}`;
    setQuery(newQuery);
  }, [date]);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["shipping"]}>
      <div className={style["container"]}>
        <CalendarToolBar
          date={date}
          view={view}
          setView={setView}
          setDate={setDate}
        />
        <div className={style["calendar-container"]}>
          {data && (
            <Calendar
              schedulerData={data}
              date={date}
              view={view}
              setView={setView}
              setDate={setDate}
              setOpenModal={setOpenModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function startOfDay(date: Date) {
  let newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

function endOfDay(date: Date) {
  let newDate = new Date(date);
  newDate.setHours(23, 59, 59, 0);
  return newDate;
}

function daysOfTheWeek(date?: Date): { start: Date; end: Date } {
  let dayOfWeek = (date || new Date()).getDay(); // 0 = Sunday, 1 = Monday, etc.
  let startOfWeek = new Date(date || new Date()); // make a copy
  startOfWeek.setDate((date || new Date()).getDate() - dayOfWeek + 1); // set to Monday of this week
  let endOfWeek = new Date(startOfWeek); // make a copy
  endOfWeek.setDate(startOfWeek.getDate() + 6); // set to Sunday of this week

  return { start: startOfWeek, end: endOfWeek };
}
