import { toast } from "react-toastify";
import AddressForm from "../../../../components/forms/addressForm/addressForm";
import ValidateButton from "../../../../components/validateButton/validateButton";
import { IContact } from "../../../../types/logistic.types";
import {
  IPickUpLocationInfo,
  IShippingPoint,
  PickUpInfo,
} from "../../../../types/shipping.types";
import style from "./addressSection.module.scss";
import { useEffect, useRef, useState } from "react";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import { getPickUpLocation } from "../../../../requests/shipping";
import { PickUpLocationAddress } from "../../../../components/forms/shippingForm/addressMiniature/pickUpLocationMiniature";
import {
  findCountyFromCountryCode,
  scrollToElement,
} from "../../../../utils/utils";
import AddressInfo from "../AddressInfo/AddressInfo";
import { IShippingMethod } from "../../../../types/accounting.types";
import { IAddress } from "../../../../types/customer.type";
import CustomerAddressSelect from "../CustomerAddressSelect/CustomerAddressSelect";
import { fetchShippingMethodsList } from "../../../../requests/shippingMethod";
import {
  GP_SHIPPING_ADDRESS,
  INITIAL_SHIPPING_ADDRESS,
} from "../../../../utils/shipping.init";

interface AddressSectionProps {
  addressesList?: IAddress[];
  setPickUpInfo: (pickUpInfo: PickUpInfo | null) => void;
  shippingMethod: IShippingMethod;
  address: IShippingPoint | null;
  setAddress: (address: IShippingPoint | null) => void;
  shippingLabel: string | null;
}

export default function AddressSection({
  addressesList,
  shippingMethod,
  setPickUpInfo,
  address,
  setAddress,
  shippingLabel,
}: AddressSectionProps) {
  const NO_ADDRESS_SELECTED = -1;
  const FORM_FILLED = 2;
  const [addressForm, setAddressForm] = useState<IShippingPoint | null>(
    address
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pickUpLocationList, setPickUpLocationList] = useState<
    IPickUpLocationInfo[]
  >([]);
  const [selectedAddress, setSelectedAddress] =
    useState<number>(NO_ADDRESS_SELECTED);
  const ref = useRef<HTMLDivElement>(null);
  const [edit, setEdit] = useState<boolean>(false);
  const [shippingError, setShippingError] = useState<string | null>(null);

  useEffect(() => {
    setShippingError(null);
    if (shippingMethod.shippingService === "DROP-OR-COLLECT") {
      setAddressForm(GP_SHIPPING_ADDRESS);
    } else {
      setAddressForm(INITIAL_SHIPPING_ADDRESS);
    }
  }, [address, shippingMethod]);

  return (
    <div className={style["address-section"]}>
      {address?.address1 && (
        <AddressInfo
          shippingForm={address}
          edit={edit}
          handleEdit={handleEdit}
        />
      )}
      {(edit || !address) && (
        <>
          {addressesList && addressesList.length > 0 && (
            <>
              <CustomerAddressSelect
                addressesList={addressesList}
                address={addressForm}
                setAddress={setAddressForm}
              />
              <hr />
            </>
          )}

          <AddressForm
            className={style["address-form"]}
            contact={address as IContact}
            defaultAddress={addressForm as IContact}
            isShippingAddress
            onChange={setAddressForm}
          />
          <ValidateButton
            className={style["generate-label-button"]}
            handleOnClick={handleValidateForm}
            isLoading={isLoading}
            error={shippingError}
            title={
              shippingMethod.shippingService === "CHRONOPOST-2SHOP" ||
              shippingMethod.shippingService === "CHRONOPOST-EUR"
                ? "Trouver le point de relais"
                : "Valider"
            }
          />
          {(shippingMethod.shippingService === "CHRONOPOST-2SHOP" ||
            shippingMethod.shippingService === "CHRONOPOST-EUR") && (
            <>
              <div className={style["pick-up-list"]} ref={ref}>
                {pickUpLocationList.map((address, index) => (
                  <PickUpLocationAddress
                    address={address}
                    isSelected={index === selectedAddress}
                    key={index}
                    setSelectedAddress={() => setSelectedAddress(index)}
                  />
                ))}
              </div>
              {selectedAddress > -1 && (
                <ValidateButton
                  className={style["generate-label-button"]}
                  title="Valider"
                  handleOnClick={handleValidateAddress}
                />
              )}
            </>
          )}
          {shippingMethod.shippingService === "INTERNAL" && <></>}
        </>
      )}
    </div>
  );

  function handleEdit() {
    if (shippingLabel) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Vous devez "Annuler" le bon chronopost actuel avant de pouvoir faire des modifications.'
      );
    }
    setEdit(!edit);
  }

  function handleValidateAddress() {
    if (addressForm) {
      setAddress({
        ...address,
        ...formatAddress(pickUpLocationList[selectedAddress]),
      });
      setEdit(false);
      setPickUpInfo({
        pickUpId: pickUpLocationList[selectedAddress].id,
        name: pickUpLocationList[selectedAddress].name,
      });
      setPickUpLocationList([]);
      setSelectedAddress(NO_ADDRESS_SELECTED);
    }
  }

  async function handleValidateForm() {
    if (
      shippingMethod.shippingService === "CHRONOPOST-2SHOP" ||
      shippingMethod.shippingService === "CHRONOPOST-EUR"
    ) {
      return handleGetPickUpLocation();
    }
    if (shippingMethod.shippingService === "INTERNAL") {
      const dbShippingMethod = await handleGetDbShippingMethod();
      if (
        !dbShippingMethod ||
        !dbShippingMethod.zipCodeLimit ||
        dbShippingMethod.zipCodeLimit.length === 0
      ) {
        return toast.error(
          "Un problème est survenu. Modifiez la méthode de livraison (resélectionnez la mếme) et réessayer",
          TOAST_ERROR_OPTIONS
        );
      }
      if (dbShippingMethod.zipCodeLimit?.includes(addressForm?.zipCode || "")) {
        setAddress(addressForm);
        setEdit(false);
      } else {
        setShippingError(
          "L'adresse n'est pas couverte par la livraison Galoche & Patin. Veuillez changez de méthode de livraison ou entrez une autre adresse."
        );
      }
    }
    if (shippingMethod.shippingService === "DROP-OR-COLLECT") {
      setAddress(addressForm);
      setEdit(false);
    }
  }

  async function handleGetDbShippingMethod() {
    try {
      const { shippingMethodsList } = await fetchShippingMethodsList(
        `shippingService=${shippingMethod.shippingService}`
      );
      if (shippingMethodsList[0]) {
        return shippingMethodsList[0];
      }
      toast.error(
        "Un problème est survenu. Modifiez la méthode de livraison et réessayer",
        TOAST_ERROR_OPTIONS
      );
    } catch (error) {
      toast.error(
        "Un problème est survenu. Modifiez la méthode de livraison et réessayer",
        TOAST_ERROR_OPTIONS
      );
    }
  }

  async function handleGetPickUpLocation() {
    setSelectedAddress(NO_ADDRESS_SELECTED);
    if (!addressForm) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await getPickUpLocation(
        addressForm,
        new Date(),
        shippingMethod.shippingService
      );
      setPickUpLocationList(response);
      scrollToElement(ref.current, 1000);
    } catch (error: any) {
      toast.error(
        "Un problème est survenu. Veuillez entrez une adresse avec un numéro de rue.",
        TOAST_ERROR_OPTIONS
      );
      setPickUpLocationList([]);
    } finally {
      setIsLoading(false);
    }
  }

  function formatAddress(address: IShippingPoint & IPickUpLocationInfo) {
    return {
      address1: address?.address1 || "",
      address2: address?.address2 || "",
      city: address?.city || "",
      zipCode: address?.zipCode || "",
      country: findCountyFromCountryCode(
        address?.countryCode || address?.country_code || "FR"
      ),
      countryCode: address?.countryCode || address?.country_code || "FR",
    };
  }
}
