import { ReactElement, useEffect, useState } from "react";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { ILogistic } from "../../../../types/logistic.types";
import style from "./productsList.module.scss";
import ProductCard from "../productCard/ProductCard";
import LinkedProductCard from "../productCard/LinkedProductCard";
import zIndex from "@mui/material/styles/zIndex";
import { queryObject } from "../../../../types/query.types";

interface ProductsListProps {
  data?: ILogistic[];
  isLoading: boolean;
  isFetching: boolean;
  refetch: () => void;
  to: string;
  query: queryObject;
  addLimit: () => void;
  showMore: boolean;
  useTrackId?: boolean;
}

export default function ProductsList({
  data,
  isLoading,
  isFetching,
  refetch,
  to,
  query,
  addLimit,
  showMore,
  useTrackId = false,
}: ProductsListProps) {
  const [logisticsList, setLogistic] = useState<ILogistic[] | null>(null);

  useEffect(() => {
    if (!!query.workshopId) {
      setLogistic(data || null);
    } else {
      setLogistic(null);
    }
  }, [data, query.workshopId]);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!logisticsList) {
    return (
      <div className={style["logistic-list"]}>
        Commencez par saisir un numéro de ticket.
      </div>
    );
  }

  return (
    <div className={style["logistic-list"]}>
      {isLoading ? (
        <LoadingSpinner color={"gold"} />
      ) : (
        logisticsList.map((logistic, logisticIndex) => {
          return (
            <div className={style["products-list"]} key={logisticIndex}>
              {logistic.productsList
                .filter((p) => p.workshopId?.includes(query.workshopId || ""))
                .map((product, index) => {
                  return (
                    <>
                      {!!to ? (
                        <LinkedProductCard
                          contact={logistic.contact}
                          key={index}
                          product={product}
                          to={`${to}${useTrackId ? logistic.trackId : product.id}`}
                        />
                      ) : (
                        <ProductCard
                          contact={logistic.contact}
                          key={index}
                          refetch={refetch}
                          product={product}
                          zIndex={
                            (logisticsList.length - logisticIndex) * 10 +
                            (logistic.productsList.length - index)
                          }
                        />
                      )}
                    </>
                  );
                })}
            </div>
          );
        })
      )}
      {showMore && (
        <div className={style["show-more"]}>
          {isFetching ? (
            <LoadingSpinner color={"gold"} />
          ) : (
            <div onClick={addLimit}>afficher plus...</div>
          )}
        </div>
      )}
    </div>
  );
}
