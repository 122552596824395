import { Link } from "react-router-dom";
import style from "./index.module.scss";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import { useState } from "react";
import { queryObject } from "../../types/query.types";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { useQuery } from "react-query";
import { deleteLicense, getLicensesList } from "../../requests/licenses";
import { ILicense } from "../../types/license.types";
import Item from "./listItem/item";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import Button from "../../components/Button/Button";

export const INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  sort: "",
};
const LICENSE_SEARCH_OPTIONS = ["name"];

export default function Licenses() {
  const [query, setQuery] =   useSaveQueryStorage(INITIAL_QUERY, "licenses");
  const { refetch, data, isLoading }: any = useQuery(
    ["licensesList", query],
    () => getLicensesList(formatedQuery()),
    { keepPreviousData: true }
  );


  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Licences</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <Button>+ Ajouter nouveau</Button>
        </Link>
      </div>
      <div className={style["filters"]}>
        {/* <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={LICENSE_SEARCH_OPTIONS}
        /> */}
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Nom</div>
          <div className={style["item-element"]}>Boutiques</div>
          <div className={style["item-element"]}>Mensualités</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        <div>
          {data &&
            data.licensesList &&
            data.licensesList.map((value: ILicense, key: number) => {
              return <Item key={key} data={value} onDelete={handleDelete} />;
            })}
        </div>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(id: string) {
    await deleteLicense({ id });
    refetch();
  }

  function formatedQuery() {
    let formatedQuery = `?limit=${query.limit}&offset=${
      query.limit * query.offset
    }`;

    return formatedQuery;
  }
}
