import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from "./modalPortal.module.scss";
import { useRemoveBodyScroll } from "../../hooks/useRemoveBodyScroll";
import { Close } from "@mui/icons-material";

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
}

export default function ModalPortal({ isOpen, children }: ModalProps) {
  const ref = useRef<Element | null>(null);

  useEffect(() => {
    ref.current = document.getElementById("modal-root");
  }, []);

  if (!isOpen) {
    return <></>;
  }
  return ref.current ? createPortal(children, ref.current) : <></>;
}

interface ModalContainerProps {
  children: React.ReactNode;
  handleCancel?: () => void;
}

export function ModalContainer({
  children,
  handleCancel,
}: ModalContainerProps) {
  useRemoveBodyScroll();

  return (
    <div className={styles["modal-container"]}>
      <div className={styles["close-button-container"]}>
        <div className={styles["close-button"]} onClick={handleCancel}>
          <Close />
        </div>
      </div>
      <div className={styles["modal-container-children"]}>{children}</div>
      <div className={styles["background"]} onClick={handleCancel}></div>
    </div>
  );
}

interface SmallModalContainerProps {
  children: React.ReactNode;
  handleCancel?: () => void;
}

export function SmallModalContainer({
  children,
  handleCancel,
}: SmallModalContainerProps) {
  useRemoveBodyScroll();

  return (
    <div className={styles["modal-container"]}>
      <div className={styles["small-modal-container-children"]}>{children}</div>
      <div className={styles["background"]} onClick={handleCancel}></div>
    </div>
  );
}
