import { Download, Edit, Launch, LocationOn, Phone } from "@mui/icons-material";
import { SyntheticEvent, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import DEFAULT_PICTURE from "../../../assets/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg";
import logo from "../../../assets/logo-chronopost.png";
import { handleGetShippingLabel } from "../../../components/forms/shippingForm/externalShipping/externalShipping";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import useLogout from "../../../hooks/useLogout";
import { fetchShipping } from "../../../requests/shipping";
import translate from "../../../translation";
import { IOperation } from "../../../types/accounting.types";
import { IProduct } from "../../../types/logistic.types";
import { IShipping } from "../../../types/shipping.types";
import { PRINTMODE } from "../../../utils/shipping.init";
import { stateColor } from "../calendar/shippingModal/shippingModal";
import style from "./readOne.module.scss";
import { authloader } from "../..";

export async function loader({ params }: { params: any }) {
  try {
    const logistics = await fetchShipping(params);
    authloader();
    return logistics;
  } catch (error: any) {
    throw error;
  }
}

export default function ReadOne() {
  const data: IShipping | null = useLoaderData() as IShipping;
  const [printMode, setPrintMode] = useState<string>("ZPL");

  useLogout(data);

  const shippingData =
    data?.direction === "OUTGOING" ? data?.recipient : data?.sender;

  return (
    <div className={style["read-one"]}>
      <div className={style["read-one-container"]}>
        <div className={style["modal-top"]}>
          <div>
            <div className={style["date"]}>
              {data?.period &&
                new Date(data?.period.start).toLocaleDateString()}
            </div>
            {data?.period && (
              <div className={style["time-range"]}>
                {new Date(data.period.start || "").toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                {" - "}
                {new Date(data.period.end || "").toLocaleTimeString("fr-FR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            )}
          </div>
          <div className={style["direction-container"]}>
            {data?.direction && (
              <div
                className={`${
                  data?.direction === "OUTGOING"
                    ? style["outgoing"]
                    : style["incoming"]
                } 
                ${style["direction"]}`}
              >
                {translate(data?.direction)}
              </div>
            )}
            {(data.shippingMethod?.shippingService === "CHRONOPOST-2SHOP" ||
              data.shippingMethod?.shippingService === "CHRONOPOST-EUR") && (
              <img
                alt="Chronopost"
                className={style["external-shipping-logo"]}
                src={logo}
              />
            )}
          </div>
        </div>
        {data?.contact && (
          <div className={style["nom-complet"]}>
            {data?.contact.givenname} {data?.contact.familyname}
            <Link
              className={style["access-button"]}
              to={`/overview/${data?.trackId}`}
            >
              <Launch className={style["access-button-icon"]} />
            </Link>
          </div>
        )}
        <hr />
        <div className={style["address"]}>
          <div className={style["address-left"]}>
            <div>
              {shippingData?.address1} {shippingData?.address2}
            </div>
            <div>
              {shippingData?.zipCode} {shippingData?.city}{" "}
              {shippingData?.country}
            </div>
            <div className={style["address-comment"]}>
              {shippingData?.comments}
            </div>
          </div>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["address-icon"]}
            to={`http://maps.google.com/?q=${shippingData?.address1} ${shippingData?.address2} ${shippingData?.zipCode} ${shippingData?.city}`}
          >
            <LocationOn />
          </Link>
        </div>
        <hr />
        <div className={style["address"]}>
          <div className={style["address-left"]}>{shippingData?.phone}</div>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["address-icon"]}
            to={`tel:${data?.contact?.phone || "indisponible"}`}
          >
            <Phone />
          </Link>
        </div>
        <hr />
        <div className={style["state"]}>
          <div className={style["select-state"]}>
            {`Status de la ${translate(
              data?.direction || ""
            ).toLocaleLowerCase()} : `}
            <div className={stateColor(data?.state || "")}>
              {translate(data?.state || "")}
            </div>
          </div>
        </div>
        <hr />
        {(data.shippingMethod?.shippingService === "CHRONOPOST-2SHOP" ||
          data.shippingMethod?.shippingService === "CHRONOPOST-EUR") &&
          data?.externalShipping && (
            <>
              {data?.externalShipping.externalId && (
                <div className={style["external-shipping-container"]}>
                  Chronopost suivi:
                  <div className={style["external-shipping-action"]}>
                    <div className={style["external-id"]}>
                      <Link
                        onClick={() => window.scrollTo(0, 0)}
                        to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${data?.externalShipping.externalId}&langue=fr`}
                      >
                        {data.externalShipping.externalId}
                      </Link>
                    </div>
                    {data.externalShipping.reservationNumber && (
                      <div className={style["download-container"]}>
                        <select
                          onChange={({ target }) => setPrintMode(target.value)}
                        >
                          {PRINTMODE.map((value: string[], index: number) => {
                            return (
                              <option value={value[1]} key={index}>
                                {value[0]}
                              </option>
                            );
                          })}
                        </select>
                        <div
                          className={style["download-button"]}
                          onClick={() =>
                            data.externalShipping &&
                            handleGetShippingLabel(printMode, data)
                          }
                        >
                          <Download />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <hr />
            </>
          )}
        <div className={style["product-list"]}>
          {`Articles (${(data?.productsList || []).length}) :`}
          {data.productsList &&
            data?.productsList.map((product: IProduct, key: number) => {
              return (
                <div key={key} className={style["product"]}>
                  <div className={style["product-info"]}>
                    <div>{product.brand}</div>
                    {" - "}
                    <div>{translate(product.productGroup)}</div>
                    {" - "}
                    <div>{translate(product.operationGroup)}</div>
                  </div>
                  {`Opérations (${product.operationsList.length}) :`}
                  <div>
                    {product.operationsList.map(
                      (operation: IOperation, key: number) => {
                        if (!operation.canceled) {
                          return (
                            <div className={style["operation"]} key={key}>
                              <div>
                                {operation.operationType &&
                                  translate(operation.operationType)}
                              </div>
                              <div>{operation.description}</div>
                              <div>{operation.comments}</div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      }
                    )}
                  </div>
                  {/* <hr /> */}
                  <div>{product.comments}</div>
                </div>
              );
            })}
        </div>
        {`Images :`}
        <div className={style["image-list"]}>
          {data?.imagesList &&
            data.imagesList.map((imageURI: string, key: number) => {
              return (
                <div key={key}>
                  <img
                    className={style["image-preview"]}
                    src={`${process.env.REACT_APP_API_URL}/file/${imageURI}`}
                    alt="previewShipping"
                    onError={handleImageError}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <ProtectedComponent roles={["ROLE_ADMIN"]}>
        <div className={style["tools"]}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to={`/shipping/edit/${data.id}`}
            className={style["tools-icon"]}
          >
            <Edit className={style["edit-icon"]} />
          </Link>
          {/* <div className={style["tools-icon"]}>
            <Delete
              className={style["delete-icon"]}
              onClick={() => handleDelete(data.id)}
            />
          </div> */}
        </div>
      </ProtectedComponent>
    </div>
  );

  function handleImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    const target = event.target as HTMLImageElement;
    target.src = DEFAULT_PICTURE;
  }
}
