import { IQuote } from "../../../types/accounting.types";
import style from "./item.module.scss";
import { Delete, Edit, Launch, Send } from "@mui/icons-material";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import QuoteModal from "../../../components/modals/quoteModal/quoteModal";
import { useModalComponent } from "../../../context/modalComponent.context";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";

export default function Item({
  data,
  onDelete,
  handleSendQuote,
  zIndex,
}: {
  data: IQuote;
  onDelete: (id?: string) => void;
  handleSendQuote: (value: IQuote) => void;
  zIndex: number;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => openQuoteModal(),
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/quotes/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  if (data.state === "QUOTE_PENDING") {
    MORE_TOOLS_OPTIONS.unshift({
      icon: <Send />,
      name: "Envoyer",
      onClick: () => handleSendQuote(data),
    });
  }

  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>{data.contact?.displayname}</div>
      <div className={style["item-element"]}>
        {data.sentAt && new Date(data.sentAt).toLocaleDateString()}
      </div>
      <div className={style["item-element"]}>
        {data.repliedAt && new Date(data.repliedAt).toLocaleDateString()}
      </div>
      <div className={style["item-element"]}>{data.minimumDurationDays}</div>
      <div className={style["item-element"]}>{data.total + " €"}</div>
      <div className={style["item-element"]}>
        <div
          className={style["state-container"]}
          style={{ backgroundColor: getStatusColor(data.state) }}
        >
          {data.state && translate(data.state)}
        </div>
      </div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <MoreTools options={MORE_TOOLS_OPTIONS} zIndex={zIndex} />
      </div>
    </div>
  );

  function openQuoteModal() {
    if (data.id) {
      setModalIsOpen(true);
      setModalChildren(<QuoteModal id={data.id} />);
    }
  }
}
