import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import style from "./addressForm.module.scss";
import InputContainer from "../inputs/inputContainer/inputContainer";
import { IShippingPoint } from "../../../types/shipping.types";
import { INITIAL_SHIPPING_ADDRESS } from "../../../utils/shipping.init";
import { IValidationError } from "../../../types";
import { IContact } from "../../../types/logistic.types";
import Input from "../../inputs/Input/Input";
import Select from "../../Select/Select";

declare global {
  interface Window {
    google: any;
  }
}

interface AddressFormProps {
  defaultAddress?: IShippingPoint;
  contact?: IContact;
  isShippingAddress: boolean;
  isDisabled?: boolean;
  className?: string;
  direction?: string;
  validationError?: IValidationError[];
  setValidationError?: (validationError: IValidationError[]) => void;
  onChange: (address: IShippingPoint, isShippingAddress: boolean) => void;
}

export default function AddressForm({
  defaultAddress,
  className,
  contact,
  isShippingAddress,
  isDisabled = false,
  direction,
  validationError,
  setValidationError,
  onChange,
}: AddressFormProps) {
  const [addressForm, setAddressForm] = useState<IShippingPoint>(
    initialAddress()
  );

  const [autoCompleteAddress, setAutoCompleteAddress] = useState<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const prefix = isShippingAddress
    ? direction === "INCOMING"
      ? "sender."
      : "recipient."
    : "contact.";

  useEffect(() => {
    if (inputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          componentRestrictions: { country: addressForm.countryCode || "FR" },
        }
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace().address_components;
        place && setAutoCompleteAddress(place);
      });
      const input = document.getElementById(
        "pac-container"
      ) as HTMLInputElement;
    }
  }, [addressForm.countryCode]);

  useEffect(() => {
    if (defaultAddress) {
      setAddressForm(defaultAddress);
    }
  }, [defaultAddress]);

  useEffect(() => {
    if (autoCompleteAddress) {
      handleAutoCompleteChange(autoCompleteAddress);
    }
  }, [autoCompleteAddress]);

  return (
    <div className={style["address-form"]}>
      <InputContainer>
        <label className={isRequired(`${prefix}address1`)}>Adresse 1</label>
        <input
          disabled={isDisabled}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          type="text"
          name="address1"
          value={addressForm.address1 ?? ""}
          onChange={handleOnChange}
          className={style["input"]}
          onFocus={() => handleStartCorrection(`${prefix}address1`)}
        />
      </InputContainer>
      <Input
        label="Ville"
        disabled={isDisabled}
        type="text"
        name="city"
        value={addressForm.city ?? ""}
        onChange={handleOnChange}
      />
      <Input
        label="Adresse 2"
        disabled={isDisabled}
        type="text"
        name="address2"
        value={addressForm.address2 ?? ""}
        onChange={handleOnChange}
        onFocus={() => handleStartCorrection(`${prefix}address2`)}
      />
      <Input
        label="Code postal"
        disabled={isDisabled}
        type="text"
        name="zipCode"
        value={addressForm.zipCode ?? ""}
        onChange={handleOnChange}
      />
      <div>
        <label htmlFor={"countryCode"}>{"Code Pays"}</label>
        <Select
          optionsList={["FR", "BE", "CH", "LU", "IT", "MC", "AT"]}
          setValue={handleCountryCodeChange}
          value={addressForm.countryCode || ""}
        />
      </div>

      <Input
        label="Pays"
        disabled={isDisabled}
        type="text"
        name="country"
        value={addressForm.country ?? ""}
        onChange={handleOnChange}
      />
      {isShippingAddress && (
        <Input
          label="Commentaire livraison"
          disabled={isDisabled}
          type="text"
          name="comments"
          value={addressForm.comments ?? ""}
          onChange={handleOnChange}
        />
      )}
    </div>
  );

  function handleAutoCompleteChange(place: any) {
    const newAddressForm: IShippingPoint = { ...addressForm };

    newAddressForm["address1"] =
      place[0]?.short_name + " " + place[1]?.short_name;
    newAddressForm["city"] =
      place.length === 7 ? place[2]?.long_name : place[1]?.long_name;
    newAddressForm["country"] =
      place.length === 7 ? place[5]?.long_name : place[4]?.long_name;
    newAddressForm["countryCode"] =
      place.length === 7 ? place[5]?.short_name : place[4]?.short_name;
    newAddressForm["zipCode"] =
      place.length === 7 ? place[6]?.short_name : place[5]?.short_name;

    setAddressForm(newAddressForm);
    onChange(newAddressForm, isShippingAddress);
  }

  function handleCountryCodeChange(code: string) {
    const newAddressForm = { ...addressForm };
    newAddressForm.countryCode = code;
    setAddressForm(newAddressForm);
    onChange(newAddressForm, isShippingAddress);
  }

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newAddressForm = { ...addressForm };
    newAddressForm[target.name as keyof IShippingPoint] = target.value;
    setAddressForm(newAddressForm);
    onChange(newAddressForm, isShippingAddress);
  }

  function handleStartCorrection(name: string) {
    if (!validationError) {
      return;
    }
    const prefix = isShippingAddress
      ? direction === "INCOMING"
        ? "sender."
        : "recipient."
      : "contact.";
    const newValidation = (validationError as IValidationError[]).filter(
      (value: IValidationError) => value.field !== name
    );
    setValidationError && setValidationError([...newValidation]);
  }

  function isRequired(name: string): string {
    if (validationError) {
      if (
        validationError.find((value: IValidationError) => name === value.field)
      ) {
        return style["validation-error"];
      }
    }
    return style["my-input"];
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  function initialAddress() {
    let data = { ...(defaultAddress || INITIAL_SHIPPING_ADDRESS) };
    data.displayname = contact?.displayname && contact.displayname;
    data.givenname = contact?.givenname && contact.givenname;
    data.familyname = contact?.familyname && contact.familyname;
    data.organization = contact?.organization && contact.organization;
    data.phone = contact?.phone && contact.phone;
    data.email = contact?.email && contact.email;

    return data;
  }
}
