import clsx from "clsx";
import styles from "./shippingServiceButton.module.scss";

interface ShippingServiceButtonProps {
  name: string;
  isSelected: boolean;
  shippingService: string;
  handleSelectShippingService: (value: string) => void;
}

export default function ShippingServiceButton({
  name,
  shippingService,
  isSelected,
  handleSelectShippingService,
}: ShippingServiceButtonProps) {
  return (
    <div
      className={clsx(
        styles["shipping-service-button"],
        isSelected && styles["toggled-button"]
      )}
      onClick={() => handleSelectShippingService(shippingService)}
    >
      {name}
    </div>
  );
}
