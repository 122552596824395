import { Cancel, Check } from "@mui/icons-material";
import style from "./crossSellControl.module.scss";
import { useMemo, useState } from "react";
import { ICrossSellLogistic } from "../../../../types/logistic.types";
import WorkshopId from "../../../../components/workshopId/workshopId";
import Button from "../../../../components/Button/Button";
import { CheckListItem } from "../../../../types";
import Checklist from "../../../../components/Checklist/Checklist";

interface CrossSellControlProps {
  crossSell?: ICrossSellLogistic;
  state: boolean;
  rejectControl: () => void;
  validateControl: () => void;
}

export default function CrossSellControl({
  crossSell,
  state,
  rejectControl,
  validateControl,
}: CrossSellControlProps) {
  const [crossSellCheckList, setCrossSellCheckList] = useState<CheckListItem[]>(
    initCrossSellChecklist()
  );
  const DISABLED = useMemo(
    () => crossSellCheckList.some((cs) => !cs.checked),
    [crossSellCheckList]
  );

  if (!crossSell) {
    return <></>;
  }

  return (
    <div className={style["cross-sell-control"]}>
      <div className={style["title"]}>
        Articles cross-selling
        <WorkshopId
          workshopId={crossSell.workshopId}
          size={"small"}
          type={"cross-sell"}
        />
      </div>
      <Checklist
        checklist={crossSellCheckList}
        setChecklist={setCrossSellCheckList}
      />
      <div className={style["button-container"]}>
        <Button className={style["cancel"]} onClick={rejectControl}>
          <Cancel />
          Retour Atelier
        </Button>
        <Button
          disabled={DISABLED}
          onClick={validateControl}
          className={DISABLED ? style["disabled"] : style["validate"]}
        >
          <Check />
          Valider
        </Button>
      </div>
    </div>
  );

  function initCrossSellChecklist() {
    return (crossSell?.crossSellItems || []).map((cs) => {
      return {
        description: `${cs.description}${
          cs.color ? ` - ${cs.color}` : ""
        } - (x${cs.cartQuantity})`,
        checked: state ? state : cs.isItemReady,
      };
    });
  }
}
