import { IProduct } from "../../../types/logistic.types";
import styles from "./printProductLabelModal.module.scss";
import {
  getOperationsListAcronym,
  parseNonConventionalOperations,
  parseOperationsAcronym,
} from "../../../utils/parseLabelOperation.function";
import FrontLabel from "../../../routes/dev_tools/ble_printer/FrontLabel";
import RearLabelList from "../../../routes/dev_tools/ble_printer/RearLabelList";
import PrinterConnectHeader from "../../../routes/dev_tools/ble_printer/PrinterConnectHeader";
import { useState } from "react";
import PrintOperationsChecklist from "../../PrintOperationsCheckList/PrintOperationsChecklist";

interface PrintProductLabelModalProps {
  product: IProduct;
  dueDate?: Date;
}

export default function PrintProductLabelModal({
  product,
  dueDate,
}: PrintProductLabelModalProps) {
  const [operationFlatAcronym, setOperationFlatAcronym] = useState<string[]>(
    getOperationsListAcronym(product.operationsList || [])
  );
  const operationsAcronym = parseOperationsAcronym(
    product.operationsList || [],
    operationFlatAcronym
  );
  const nonConventionalOperations = parseNonConventionalOperations(
    product.operationsList || []
  );

  return (
    <div className={styles["print-product-label-modal"]}>
      <h3>Impression ticket</h3>
      <PrinterConnectHeader />
      <PrintOperationsChecklist
        operations={operationFlatAcronym}
        toggleAcronymInList={toggleAcronymInList}
      />
      <div className={styles["label-preview-container"]}>
        <FrontLabel
          operations={operationsAcronym}
          workshopId={product.workshopId || ""}
          dueDate={dueDate || null}
        />
        <RearLabelList
          operations={nonConventionalOperations}
          workshopId={product.workshopId || ""}
          dueDate={dueDate || null}
        />
      </div>
    </div>
  );

  function toggleAcronymInList(operationAcronym: string) {
    if (operationFlatAcronym.includes(operationAcronym)) {
      setOperationFlatAcronym((prev) =>
        prev.filter((op) => op !== operationAcronym)
      );
    } else {
      setOperationFlatAcronym((prev) => [...prev, operationAcronym]);
    }
  }
}
