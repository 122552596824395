import {
  FormProduct,
  IContact,
  ILogistic,
  IProduct,
} from "../../../../../types/logistic.types";

import { useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQuery } from "react-query";
import { updateLogisticMutation } from "../../../../../requests/logistic";
import { DEFAULT_PRODUCT } from "../../../../../utils/logistic.init";
import styles from "./checkIn.module.scss";
import { deleteFile } from "../../../../../requests/file";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../../utils/toast.options";
import { useNavigate } from "react-router-dom";
import { SaveWidget } from "./SaveWidget/SaveWidget";
import SliderContainer from "./SliderContainer/SliderContainer";
import ProductTab from "./ProductTab/ProductTab";
import { IValidationError } from "../../../../../types";
import CheckInContactForm from "./CheckInContactForm/CheckInContactForm";
import ModalPortal from "../../../../../components/modals/ModalPortal";
import ConfirmModal from "../../../../../components/modals/ConfirmModal/ConfirmModal";

interface CheckInProps {
  fetchingMethod: (data: any) => Promise<any>;
  handleSaveClicked: (
    logistic: ILogistic,
    setLogistic: (value: ILogistic) => void,
    updateLogistic: UseMutateFunction<
      string | IValidationError[] | null,
      unknown,
      {
        logistic: ILogistic;
        updateQuote: boolean;
        setShippingToDelivered: boolean;
      },
      unknown
    >
  ) => void;
  trackId: string;
}

export default function CheckIn({
  fetchingMethod,
  handleSaveClicked,
  trackId,
}: CheckInProps) {
  const [selectedProduct, setSelectedProduct] = useState<
    undefined | FormProduct
  >(undefined);

  const navigate = useNavigate();
  const [logistic, setLogistic] = useState<ILogistic>();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { data, refetch } = useQuery(
    ["cart-to-logistic", trackId, fetchingMethod],
    () => {
      return fetchingMethod({ params: { trackerId: trackId } });
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess(data) {
        if (selectedProduct) {
          changeSelectedProduct(
            selectedProduct.id,
            JSON.parse(JSON.stringify(data.logistic))
          );
        } else {
          changeSelectedProduct(
            data.logistic.productsList.at(0)?.id,
            JSON.parse(JSON.stringify(data.logistic))
          );
        }
      },
    }
  );
  const [needsUpdate, setNeedsUpdate] = useState(false);

  const { mutate: updateLogistic } = useMutation(updateLogisticMutation, {
    onSuccess() {
      if (
        logistic?.productsList.every((p: FormProduct) => p.hasError === false)
      ) {
        setConfirmModal(true);
        // window.confirm("Voulez-vous générer un devis ?")
        //   ? navigate(`/quick-access/quote-edit/${trackId}`)
        //   : navigate(`/quick-access`);
      }
      refetch();
    },
    onError() {
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const { mutate: deleteImage } = useMutation(deleteFile, {
    onSuccess() {
      refetch();
    },
    onError() {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
  });

  useEffect(() => {
    if (logistic && data?.logistic) {
      let up = logisticsAreDifferent(data.logistic, logistic);
      if (up !== needsUpdate) {
        setNeedsUpdate(up);
      }
    }
  }, [logistic, data]);

  if (!logistic) {
    return <></>;
  }

  return (
    <>
      <div className={styles["checkin"]}>
        <div className={styles["checkin-header"]}>
          <CheckInContactForm
            contact={logistic.contact}
            onChange={handleContactChange}
          />
          <SaveWidget
            onClick={() =>
              handleSaveClicked(logistic, setLogistic, updateLogistic)
            }
            disabled={false}
            className={styles["responsive"]}
          />
        </div>
        <SliderContainer
          onAddClicked={handleAddProduct}
          logistic={logistic}
          onProductSelected={handleProductSelected}
          onProductDelete={handleDeleteProduct}
          disabled={
            logistic.productsList.at(-1)?.id === undefined ||
            logistic.productsList.at(-1)?.id === ""
          }
        />
        {selectedProduct && (
          <ProductTab
            trackId={trackId}
            product={selectedProduct}
            onChange={handleProductChanged}
          />
        )}
        <SaveWidget
          onClick={() =>
            handleSaveClicked(logistic, setLogistic, updateLogistic)
          }
          disabled={false}
          className={styles["responsive-mobile"]}
        />
      </div>
      <ModalPortal isOpen={confirmModal}>
        <ConfirmModal
          title={"Voulez-vous générer un devis ?"}
          onConfirm={() => {
            navigate(`/quick-access/quote-edit/${trackId}`);
            setConfirmModal(!confirmModal);
          }}
          onCancel={() => {
            navigate(`/quick-access`);
            setConfirmModal(!confirmModal);
          }}
        />
      </ModalPortal>
    </>
  );

  function logisticsAreDifferent(logistic1: ILogistic, logistic2: ILogistic) {
    return (
      JSON.stringify(logistic1) !==
      JSON.stringify(logistic2, (key, value) =>
        key === "selected" ? undefined : value
      )
    );
  }

  function updateLogisticProduct(product: IProduct, logisticData: ILogistic) {
    if (!logisticData || !logisticData.productsList) {
      return;
    }
    let productIndex = logisticData.productsList.findIndex(
      (p: FormProduct) => p.selected
    );
    if (productIndex < 0) {
      return;
    }
    logisticData.productsList[productIndex] = product;
    setLogistic({ ...logisticData });
    setSelectedProduct(product);
  }

  function getSelectedProduct(logisticData: ILogistic) {
    if (!logisticData || !logisticData.productsList) {
      return undefined;
    }
    let p = logisticData.productsList.find((p: FormProduct) => p.selected);
    return p;
  }

  function changeSelectedProduct(
    productId: string | undefined,
    logisticData: ILogistic
  ) {
    if (!logisticData) {
      return;
    }
    for (
      let productIndex = 0;
      productIndex < logisticData.productsList.length;
      productIndex++
    ) {
      const product: FormProduct = logisticData.productsList[productIndex];
      if (product.id === productId) {
        (
          logisticData.productsList[productIndex] as IProduct & {
            selected?: boolean;
          }
        ).selected = true;
      } else {
        (
          logisticData.productsList[productIndex] as IProduct & {
            selected?: boolean;
          }
        ).selected = false;
      }
    }
    setLogistic({ ...logisticData });
    setSelectedProduct(getSelectedProduct(logisticData));
  }

  function handleAddProduct() {
    if (!logistic) {
      return;
    }
    let newLogistic = { ...logistic };
    newLogistic.productsList.push(DEFAULT_PRODUCT);
    changeSelectedProduct(DEFAULT_PRODUCT.id, newLogistic);
  }

  function handleDeleteProduct(product: IProduct) {
    if (!logistic) {
      return;
    }

    // Delete files
    if (
      product.incomingPicturesList &&
      product.incomingPicturesList.length > 0
    ) {
      product.incomingPicturesList.forEach((f) => deleteImage(f));
    }

    let newLogistic = { ...logistic };

    if (!product.id || product.id === "") {
      // If product has no ID, just remove last product from list. No need to update DB.
      newLogistic.productsList.pop();
      setLogistic(newLogistic);

      handleProductSelected(
        newLogistic.productsList[newLogistic.productsList.length - 1]
      );
    } else {
      // If product has an ID, remove product from list and update database.
      let productIndex = newLogistic.productsList.findIndex(
        (p) => p.id === product.id
      );
      if (productIndex > 0) {
        newLogistic.productsList.splice(productIndex, 1);
      }
      updateLogistic({
        logistic: newLogistic,
        updateQuote: true,
        setShippingToDelivered: false,
      });
    }
  }

  function handleProductChanged(product: IProduct) {
    if (!logistic) {
      return;
    }
    updateLogisticProduct(product, logistic);
  }

  function handleContactChange(contact: IContact): void {
    if (!logistic) {
      return;
    }
    let updatedLogistic = { ...logistic, contact };
    setLogistic(updatedLogistic);
  }

  // async function handleSaveClicked() {
  //   if (!logistic) {
  //     return;
  //   }
  //   let updatedLogistic: ILogistic = { ...logistic };

  //   if (!updatedLogistic.productsList) {
  //     updatedLogistic.productsList = [];
  //   }

  //   for (
  //     let productIndex = 0;
  //     productIndex < updatedLogistic.productsList.length;
  //     productIndex++
  //   ) {
  //     const product = updatedLogistic.productsList[productIndex];
  //     (updatedLogistic.productsList[productIndex] as FormProduct).hasError =
  //       !(await isProductFormValid(product));
  //   }

  //   updatedLogistic.status = "LOGISTIC_QUOTE_ESTIMATION";
  //   // setLogistic(updatedLogistic);
  //   updateLogistic({
  //     logistic: updatedLogistic,
  //     updateQuote: true,
  //     setShippingToDelivered: true,
  //   });
  // }

  function handleProductSelected(product: FormProduct): void {
    if (!logistic) {
      return;
    }
    changeSelectedProduct(product.id, logistic);
  }
}
