import { SyntheticEvent, useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDetailedProduct } from "../../../requests/product";
import style from "./readProduct.module.scss";
import ContactInfoCard from "../../../components/contactInfoCard/contactInfoCard";
import {
  ICommentObject,
  IDetailedProduct,
} from "../../../types/logistic.types";
import translate from "../../../translation";
import { IOperation } from "../../../types/accounting.types";
import getStatusColor from "../../../translation/statusColor";
import { COLOR_LIST } from "../../../translation/color";
import DEFAULT_PICTURE from "../../../assets/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg";
import { isValidJSONString } from "../../../utils/utils";
import WorkshopId from "../../../components/workshopId/workshopId";
import Image from "../../../components/image/Image";
import { useImageViewer } from "../../../context/imageViewer.context";
import State from "../../../components/State/State";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

export default function ReadProduct({ id }: { id?: string }) {
  const { productId } = useParams();
  const {
    data: detailedProduct,
    error,
    isFetching,
  } = useQuery<IDetailedProduct>(
    ["readProduct", productId],
    () => getDetailedProduct(id || productId),
    { enabled: Boolean(productId) }
  );
  const colorCode = COLOR_LIST.find(
    (value: any) => detailedProduct?.product.color === value.name
  )?.color;
  const operationNumber = detailedProduct?.product.operationsList.filter(
    (operation: IOperation) => !operation.canceled
  ).length;
  const { setViewerIsOpen, setPictureList, setIndex } = useImageViewer();
  const OTHER_ARTICLE: ICommentObject = parseComment();

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["read-product"]}>
      <ContactInfoCard
        contact={detailedProduct && detailedProduct.contact}
        trackId={detailedProduct?.trackId}
      />
      {detailedProduct && (
        <div className={style["read-product-container"]}>
          <div className={style["left"]}>
            <div className={style["left-top"]}>
              <WorkshopId
                className={style["workshop-id"]}
                workshopId={detailedProduct.product.workshopId}
                size="small"
              />
              <State
                state={detailedProduct.product.status || ""}
                className={style["state"]}
              />
            </div>
            <div className={style["product-operation-group"]}>
              {translate(detailedProduct.product.productGroup)}
              {" - "} {translate(detailedProduct.product.operationGroup)}
            </div>
            <div className={style["product-info"]}>
              <span>Marque</span> {detailedProduct.product.brand ?? "-"}
            </div>
            <div className={style["product-info-line"]}>
              <div className={style["product-info-line-item"]}>
                <span>Catégorie</span>{" "}
                {detailedProduct.product.category
                  ? detailedProduct.product.category
                  : "-"}
              </div>
              <div className={style["product-info-line-item"]}>
                <span>Modèle</span> {detailedProduct.product.model ?? "-"}
              </div>
            </div>
            <div className={style["product-info-line"]}>
              <div className={style["product-info-line-item"]}>
                <span>Couleur</span>
                <div className={style["product-color"]}>
                  {colorCode ? (
                    <div
                      className={style["color"]}
                      style={{ backgroundColor: `${colorCode}` }}
                    ></div>
                  ) : (
                    <>
                      <div className={style["empty-color"]}></div>
                      <div className={style["diagonal-line"]}></div>
                    </>
                  )}
                  {detailedProduct.product.color}
                </div>
              </div>
              <div className={style["product-info-line-item"]}>
                <span>Taille</span> {detailedProduct.product.size ?? "-"}
              </div>
            </div>
            <div className={style["operation-list-container"]}>
              <span>
                Liste des opérations{" ("}
                {operationNumber}
                {")"}
              </span>
              <ul className={style["operation-list"]}>
                {detailedProduct.product.operationsList.map(
                  (operation: IOperation, index: number) => {
                    if (!operation.canceled)
                      return <li key={index}>{operation.description}</li>;
                  }
                )}
              </ul>
            </div>
            {OTHER_ARTICLE && (
              <div className={style["other-article"]}>
                {OTHER_ARTICLE.embauchoir &&
                  `Embauchoir: ${OTHER_ARTICLE.embauchoir}`}
                <span>
                  {OTHER_ARTICLE.pochon && `Pochon: ${OTHER_ARTICLE.pochon}`}
                </span>
                {OTHER_ARTICLE.other && `Autres: ${OTHER_ARTICLE.other}`}
              </div>
            )}
            <div className={style["history-list"]}>
              <span>{"Historique du produit (dernière activité)"}</span>
              {detailedProduct.product.historiesList &&
              detailedProduct.product.historiesList[
                detailedProduct.product.historiesList.length - 1
              ] ? (
                <div className={style["history-item"]}>
                  <span>
                    {new Date(
                      detailedProduct.product.historiesList[
                        detailedProduct.product.historiesList.length - 1
                      ].timeStamp
                    ).toLocaleString()}
                  </span>
                  <span>
                    {translate(
                      detailedProduct.product.historiesList[
                        detailedProduct.product.historiesList.length - 1
                      ].state
                    )}
                  </span>
                  <span>
                    {detailedProduct.product.historiesList[
                      detailedProduct.product.historiesList.length - 1
                    ].comments ?? "-"}
                  </span>
                </div>
              ) : (
                <div
                  className={style["empty-history-message"]}
                >{`aucun historique`}</div>
              )}
            </div>
          </div>
          <div className={style["right"]}>
            {detailedProduct.product.incomingPicturesList && (
              <div className={style["picture-container"]}>
                <Image
                  className={style["main-picture"]}
                  src={detailedProduct.product.incomingPicturesList[0]}
                  index={0}
                  pictureList={detailedProduct.product.incomingPicturesList}
                />
                {detailedProduct.product.incomingPicturesList?.length > 1 && (
                  <div
                    className={style["more-picture"]}
                    onClick={() => handlePictureClick(0)}
                  >
                    +{detailedProduct.product.incomingPicturesList?.length - 1}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );

  function parseComment() {
    if (detailedProduct && detailedProduct.product.comments) {
      if (!isValidJSONString(detailedProduct.product.comments)) {
        return {};
      } else {
        return JSON.parse(detailedProduct.product.comments);
      }
    }
  }

  function handleImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
    const target = event.target as HTMLImageElement;
    target.src = DEFAULT_PICTURE;
  }

  function handlePictureClick(index: number) {
    if (detailedProduct?.product.incomingPicturesList) {
      setViewerIsOpen(true);
      setIndex(index);

      const formatedPictureList =
        detailedProduct?.product.incomingPicturesList.map((element: string) => {
          return `${process.env.REACT_APP_API_URL}/file/${element}`;
        });

      setPictureList(formatedPictureList || DEFAULT_PICTURE);
    }
  }
}

// function WorkshopId({ workshopId }: { workshopId: string | undefined }) {
//   return (
//     <div className={style["workshop-id"]}>
//       {workshopId}
//       <Tag />
//     </div>
//   );
// }
