import { IOperation } from "../types/accounting.types";

export const SLUG_TO_ACRONYM = new Map<string, string | string[]>([
  ["b2c_patins_chaussure_femme", "Pa"],
  ["b2c_patins_chaussure_homme", "Pa"],
  ["b2c_talons_chaussure_femme", "Ta"],
  ["b2c_talons_chaussure_homme", "Ta"],
  ["b2c_fers_encastres_chaussure_homme", "Fe"],
  ["b2c_fers_encastres_chaussure_femme", "Fe"],
  ["b2c_collage_chaussure_homme", "Coll"],
  ["b2c_collage_chaussure_femme", "Coll"],
  ["b2c_glissoirs_chaussure_homme", "Gli"],
  ["b2c_glissoirs_bottines_chaussure_homme", "Gli"],
  ["b2c_glissoirs_chaussure_femme", "Gli"],
  ["b2c_glissoirs_bottines_chaussure_femme", "Gli"],
  ["b2c_glissoirs_bottes_chaussure_femme", "Gli"],
  ["b2c_glissoirs_sneakers", "Gli"],
  ["b2c_enveloppes_chaussure_femme", "Env"],
  ["b2c_demi_semelle_cuir_chaussure_homme", "DemiC"],
  ["b2c_demi_semelle_cuir_chaussure_femme", "DemiC"],
  ["b2c_demi_semelle_caoutchouc_chaussure_homme", "DemiCaout"],
  ["b2c_demi_semelle_caoutchouc_chaussure_femme", "DemiCaout"],

  ["b2c_ressemelage_cuir_chaussure_homme", "RC"],
  ["b2c_ressemelage_caoutchouc_chaussure_homme", "RCaout"],
  ["b2c_ressemelage_crêpe_chaussure_homme", "RCrêpe"],
  ["b2c_ressemelage_cuir_chaussure_femme", "RC"],
  ["b2c_ressemelage_caoutchouc_chaussure_femme", "RCaout"],
  ["b2c_ressemelage_crepe_chaussure_femme", "RCrêpe"],
  ["b2c_ressemelage_sneakers", "RC"],
  ["b2c_ressemelage_escalade", "RC"],
  ["b2c_enrobage_et_ressemelage_escalade", "RC"],
  ["ressemelage_birkenstock_chaussure_homme", "RC Birk"],
  ["ressemelage_docmartens_chaussure_homme", "RC Doc"],
  ["b2c_ressemelage_birkenstock_chaussure_femme", "RC Birk"],
  ["b2c_ressemelage_docmartens_chaussure_femme", "RC Doc"],

  ["b2c_soin_creme_cirage_chaussure_homme", "Soins"],
  ["b2c_soin_nettoyage_daim_chaussure_homme", "Soins"],
  ["b2c_soin_glacage_chaussure_homme", "Soins"],
  ["b2c_soin_creme_cirage_chaussure_femme", "Soins"],
  ["b2c_soin_nettoyage_daim_chaussure_femme", "Soins"],
  ["b2c_soin_nettoyage_sneakers_sneakers", "Soins"],
  ["b2c_soin_nettoyage_sneakers_complexe_sneakers", "Soins"],
  ["b2c_soin_nettoyage_ext_sac_25cm_maroquinerie", "Soins"],
  ["b2c_soin_nettoyage_ext_sac_+25cm_maroquinerie", "Soins"],
  ["b2c_soin_nettoyage_veste__maroquinerie", "Soins"],
  ["soin_nettoyage_matieres_sensibles_homme", "Soins"],
  ["soin_nettoyage_matieres_sensibles_femme", "Soins"],
  ["b2c_soin_nettoyage_repigmentation_sneakers", "Soins"],

  ["b2c_teinture_chaussure_homme", "Teinture"],
  ["b2c_teinture_bottines_chaussure_homme", "Teinture"],
  ["b2c_teinture_chaussure_femme", "Teinture"],
  ["b2c_teinture_bottines_chaussure_femme", "Teinture"],
  ["b2c_teinture_sneakers", "Teinture"],
  ["b2c_teinture_bottines_sneakers", "Teinture"],
  ["b2c_soin_changement_teinture", "Teinture"],
  ["b2c_teinture_masse", "Teinture"],
  ["b2c_premiere_de_proprete_chaussure_homme", "1ère"],
  ["b2c_premiere_de_proprete_chaussure_femme", "1ère"],

  ["b2c_redresse_talons_chaussure_homme", "Re"],
  ["b2c_redresse_talons_chaussure_femme", "Re"],
  ["b2c_collage_sneakers", "Coll"],
  ["b2c_collage_complexe_sneakers", "Coll"],
  ["b2c_patins_redresse_bouts_chaussure_homme", ["Pa", "Re"]],
  ["b2c_patins_redresse_bouts_chaussure_femme", ["Pa", "Re"]],
  ["b2c_talons_redresse_talons_chaussure_homme", ["Ta", "Re"]],
  ["b2c_talons_redresse_talons_chaussure_femme", ["Ta", "Re"]],
  ["b2c_patins_fers_encastres_chaussure_homme", ["Pa", "Fe"]],
  ["b2c_patins_fers_encastres_chaussure_femme", ["Pa", "Fe"]],
  [
    "b2c_patins_redresse_bouts_fers_encastrés_chaussure_homme",
    ["Pa", "Re", "Fe"],
  ],
  ["b2c_collage_couture_semelle_chaussure_homme", ["Coll", "Cou"]],
  ["b2c_collage_couture_semelle_chaussure_femme", ["Coll", "Cou"]],
  ["b2c_collage_couture_semelle_sneakers", ["Coll", "Cou"]],

  // ["b2c_changement_de_l_estampe_de_talon_chaussure_homme", ""],
  // ["b2c_changement_de_l_estampe_de_talon_chaussure_femme", ""],
  // ["b2c_changement_du_bas_des_envelopes_de_talons_chaussure_femme", ""],
  // ["b2c_demi_semelle_regent_chaussure_homme", ""],
  // ["b2c_demi_semelle_regent_redresse_bouts_chaussure_homme", ""],
  // ["b2c_demi_semelle_regent_chaussure_femme", ""],
  // ["b2c_demi_semelle_regent_redresse_bouts_chaussure_femme", ""],
]);

export const ACRONYM_LEVEL = new Map<string, number>([
  ["Pa", 0],
  ["Re", 0],
  ["Ta", 0],
  ["Fe", 0],

  ["Coll", 1],
  ["Gli", 1],
  ["Cou", 1],
  ["Env", 1],

  ["DemiC", 2],
  ["DemiCaout", 2],
  ["RC", 2],
  ["RCaout", 2],
  ["RCrêpe", 2],
  ["RC Birk", 2],
  ["RC Doc", 2],

  ["1ère", 3],
  ["Teinture", 3],
  ["Soins", 3],
]);

export const ACRONYM = [
  "Pa",
  "Re",
  "Ta",
  "Fe",
  "Coll",
  "Gli",
  "Cou",
  "Env",
  "DemiC",
  "DemiCaout",
  "RC",
  "RCaout",
  "RCrêpe",
  "RC Birk",
  "RC Doc",
  "1ère",
  "Teinture",
  "Soins",
];
