import { ReactNode } from "react";
import styles from "./filtersContainer.module.scss";

interface FiltersContainerProps {
  children: ReactNode;
}
export default function FiltersContainer({ children }: FiltersContainerProps) {
  return (
    <div className={styles["filters-container"]}>
      <div className={styles["filters"]}>{children}</div>
    </div>
  );
}
