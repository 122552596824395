import { IProduct } from "../../../types/logistic.types";
import styles from "./productsCell.module.scss";
import shoe_logo from "../../../assets/shoe.png";
import leather_logo from "../../../assets/leather.png";
import WorkshopId from "../../../components/workshopId/workshopId";
import { Fragment } from "react";

interface ProductsCellProps {
  productsList: IProduct[];
}

export default function ProductsCell({ productsList }: ProductsCellProps) {
  return (
    <div className={styles["item-product-list"]}>
      {productsList.map((product: IProduct, index: number) => {
        return (
          <div key={index} className={styles["item-product"]}>
            <div className={styles["item-product-left"]}>
              <ProductGroupLogo productGroup={product.productGroup} />
              <div className={styles["brand"]}>{product.brand}</div>
            </div>
            <WorkshopId
              workshopId={product.workshopId}
              size={"xxx-small"}
              type={!product.workshopId?.startsWith("1-1-") ? "pro" : ""}
            />
          </div>
        );
      })}
    </div>
  );
}

interface ProductGroupLogoProps {
  productGroup: string;
}

function ProductGroupLogo({ productGroup }: ProductGroupLogoProps) {
  switch (productGroup) {
    case "SHOES":
      return <img src={shoe_logo} className={styles["icon"]} />;
    case "LEATHER":
      return <img src={leather_logo} className={styles["icon"]} />;
    default:
      return <></>;
  }
}
