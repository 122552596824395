import { useEffect, useRef, useState } from "react";
import { IContact } from "../../types/logistic.types";
import style from "./contactInfoCard.module.scss";
import { Close, Launch, Person } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useModalComponent } from "../../context/modalComponent.context";
import { isDesktop } from "react-device-detect";

export default function ContactInfoCard({
  contact,
  className,
  trackId,
}: {
  contact: IContact | undefined;
  className?: string;
  trackId?: string;
}) {
  const [contactInfoCard, setContactInfoCard] = useState<boolean>(false);
  const { setModalIsOpen } = useModalComponent();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setContactInfoCard(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      {contact && contactInfoCard ? (
        <div className={`${style["contact-info-card-wrapper"]} ${className}`}>
          <div className={style["contact-info-container"]}>
            {!!contact.organization && <span>{contact.organization}</span>}
            <span>{contact.displayname}</span>
            <span>{contact.phone}</span>
            <span>{contact.email}</span>
            {trackId && (
              <Link
                to={`/overview/${trackId}`}
                onClick={() => setModalIsOpen(false)}
                className={style["overview-link"]}
                target={isDesktop ? "_blank" : undefined}
              >
                Vue d'ensemble
                <Launch />
              </Link>
            )}
          </div>
          <div
            className={style["close-button"]}
            onClick={() => setContactInfoCard(false)}
          >
            <Close />
          </div>
        </div>
      ) : (
        <div
          className={`${style["small-contact-info-card-wrapper"]} ${className}`}
          onClick={() => setContactInfoCard(true)}
        >
          <Person />
        </div>
      )}
    </div>
  );
}
