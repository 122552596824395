import styles from "./item.module.scss";
import { useState } from "react";
import { ILogistic } from "../../../types/logistic.types";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { useModalComponent } from "../../../context/modalComponent.context";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/StateItem/State";
import ProductsCell from "./ProductsCell";
import clsx from "clsx";
import LogisticModal from "../../../components/modals/logisticModal/logisticModal";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { useConfirmModal } from "../../../context/confirmationModalContext";

interface ItemProps {
  data: ILogistic;
  onDelete: () => void;
  refetch: () => void;
}

export default function Item({ data, onDelete, refetch }: ItemProps) {
  const [hover, setHover] = useState<Boolean>(false);
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {
        openLogisticModal();
      },
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/logistic/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => handleConfirmDelete(),
    },
  ];

  return (
    <tr
      className={styles["item"]}
      style={{
        backgroundColor: `${hover ? "#e4e4e7" : ""}`,
      }}
    >
      <td
        className={`${styles["item-cell"]} ${styles["item-date"]}`}
        title={`${
          data.createdAt ? new Date(data.createdAt).toLocaleString() : ""
        }`}
      >
        {data.createdAt
          ? new Date(data?.createdAt).toLocaleDateString("fr-FR")
          : "indisponible"}
      </td>
      <td className={styles["item-cell"]}>
        <div className={styles["item-coord"]}>
          <span className={styles["displayname"]}>
            {data.contact.displayname}
          </span>
          <span>{data.contact.organization}</span>
          <span>{data.contact.phone}</span>
          <span>{data.contact.email}</span>
        </div>
      </td>
      <td
        className={clsx(styles["item-cell"], styles["product-cell"])}
        onClick={openLogisticModal}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ProductsCell productsList={data?.productsList} />
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <State state={data.status} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  async function openLogisticModal() {
    setModalIsOpen(true);
    setModalChildren(<LogisticModal id={data.id} requiredActions={false} />);
  }

  function handleConfirmDelete() {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={styles["delete-button"]}
        title={"Voulez vous supprimer ce document ?"}
        confirmText="Supprimer"
        onConfirm={handleDelete}
      />
    );
    setConfirmModalIsOpen(true);
  }

  function handleDelete() {
    onDelete();
    refetch();
  }
}
