import { fetchShipping } from "../../../requests/shipping";
import { useQuery } from "react-query";
import Create from "../create/Create";
import { useParams } from "react-router-dom";
import useLogout from "../../../hooks/useLogout";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import style from "./edit.module.scss";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useQuery(
    "shippingDocument",
    () => fetchShipping({ id: id }),
    { refetchOnWindowFocus: false }
  );

  // useLogout(data, isFetching);
  return (
    <>
      {!isFetching ? (
        <>{data && <Create refetch={refetch} shipping={data} />}</>
      ) : (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </>
  );
}
