import { Launch } from "@mui/icons-material";
import { Link } from "react-router-dom";
import translate from "../../../translation";
import { IPayment } from "../../../types/payment.type";
import style from "./item.module.scss";
import getStatusColor from "../../../translation/statusColor";

export default function Item({ data }: { data: IPayment }) {
  return (
    <div className={style["item"]}>
      <div className={`${style["item-element"]} ${style["coordinates"]}`}>
        <span>{`${
          data.contact?.givenname
        } ${data.contact?.familyname?.toLocaleUpperCase("FR")}`}</span>
        <span>{data.contact?.phone}</span>
      </div>
      {data.transactionDate && (
        <div
          className={style["item-element"]}
          title={new Date(data.transactionDate).toLocaleString()}
        >
          {new Date(data.transactionDate).toLocaleDateString()}
        </div>
      )}
      <div className={style["item-element"]}>{data.amount / 100} €</div>
      <div className={style["item-element"]}>
        <div
          className={style["state-container"]}
          style={{ backgroundColor: getStatusColor(data.transactionStatus) }}
        >
          {data.transactionStatus && translate(data.transactionStatus)}
        </div>
      </div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <Link
          className={style["mylink"]}
          to={`/overview/${data.trackId}`}
          title={"ouvrir"}
        >
          <Launch className={style["icon"]} />
        </Link>
      </div>
    </div>
  );
}
