import style from "./read.module.scss";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import Item from "./listItem/item";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { queryObject } from "../../types/query.types";
import { getShopsList } from "../../requests/shops";
import { IShop } from "../../types/shop.types";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useSearchParams } from "react-router-dom";

const INITIAL_SHIPPING_QUERY: queryObject = {
  field: "",
  value: "",
  limit: 10,
  offset: 0,
  sort: "",
};

export default function Shops() {
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState<queryObject>(INITIAL_SHIPPING_QUERY);
  const { data, isLoading, refetch } = useQuery(
    ["shop-list", query],
    () => getShopsList(formatedQuery()),
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (searchParams.get("name")) {
      setQuery((prev) => ({ ...prev, value: searchParams.get("name") || "" }));
    }
  }, [searchParams]);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["container"]}>
      <div className={style["item-list"]}>
        <div className={style["top"]}>
          <span className={style["title"]}>Boutiques</span>
        </div>
        <div className={style["filters-group"]}>
          <SearchInput query={query.value || ""} setQuery={handleSearchInput} />
        </div>
        <div className={style["list-header"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
          <div className={style["header"]}>
            <div className={style["header-element-container"]}>Boutique</div>
            <div className={style["header-element-container"]}>
              Informations livraison
            </div>
            <div className={style["header-element-container"]}>Actions</div>
          </div>
          <div className={style["list-body"]}>
            {data && data.shops.length > 0 ? (
              data.shops.map((shop: IShop, index: number) => {
                return (
                  <Item
                    key={index}
                    refetch={refetch}
                    shop={shop}
                    zIndex={query.limit - index}
                  />
                );
              })
            ) : (
              <div className={style["empty-list"]}>aucun element trouvé</div>
            )}
          </div>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = `?limit=${
      query.limit
    }&sort_field=workshopPrefixId&sort_direction=asc&offset=${
      query.offset * query.limit
    }`;

    if (query.value) {
      formatedQuery += `&$or=${query.value}`;
    }
    return formatedQuery;
  }

  function handleSearchInput(value: string) {
    setQuery((prev) => ({ ...prev, value: value }));
  }
}
