import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import ImageViewer from "../ImageViewer/ImageViewer";

import style from "./splitPage.module.scss";
import TopBar from "./topBar/topBar.component";
import SideBar from "./sideBar.component";
import { useHideSideBar } from "../../context/hideSideBar.context";
import { WebCamCaptureContext } from "../../context/webCamCapture.context";
import ModalContainer from "../modalContainer/modalContainer";
import { useModalComponent } from "../../context/modalComponent.context";
import { useImageViewer } from "../../context/imageViewer.context";
import ConfirmationModal from "../confirmationModal/ConfirmationModal";
import { useConfirmModal } from "../../context/confirmationModalContext";
import useClickOutside from "../../hooks/useClickOutSide";

export default function SplitPage({ children }: { children: ReactNode }) {
  const { hideSide, setHideSide } = useHideSideBar();
  const { viewerIsOpen } = useImageViewer();
  const { confirmModalIsOpen } = useConfirmModal();
  const { modalIsOpen } = useModalComponent();
  const ref = useClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <div className={style["split-page"]}>
      {modalIsOpen && <ModalContainer />}
      {confirmModalIsOpen && <ConfirmationModal />}
      {viewerIsOpen && <ImageViewer />}
      <div className={`${style["top-bar"]} ${hideSide && style["expand"]}`}>
        <TopBar />
      </div>
      <div
        ref={ref}
        className={`${style["side-bar"]} ${hideSide && style["shrink"]}`}
      >
        <SideBar />
      </div>
    </div>
  );

  function handleClickOutside() {
    if (window.innerWidth < 1850) {
      setHideSide(true);
    }
  }
}
