import { useState } from "react";
import { useQuery } from "react-query";
import { fetchShippingList } from "../../../../../requests/shipping";
import InputContainer from "../../../../../components/forms/inputs/inputContainer/inputContainer";
import styles from "./incomingInternal.module.scss";
import { Replay } from "@mui/icons-material";
import LoadingSpinner from "../../../../../components/loadingSpinner/loadingSpinner";
import ShippingSelection from "../../shippingSelection/shippingSelection";

export default function IncomingInternal() {
  const [date, setDate] = useState<Date>(new Date());
  const { data, isFetching } = useQuery(
    ["shippings-list", date],
    () => fetchShippingList(formatedQuery()),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  return (
    <>
      <div>
        <InputContainer>
          <label>Choisir une date</label>
          <div className={styles["set-date-container"]}>
            <input
              type="date"
              onChange={handleDateChange}
              value={date.toISOString().substring(0, 10)}
            />
            <div
              className={styles["reset-button"]}
              onClick={() => setDate(new Date())}
            >
              <Replay />
            </div>
          </div>
        </InputContainer>
      </div>
      {isFetching ? (
        <div className={styles["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      ) : (
        <ShippingSelection shippings={data?.shippingsList} />
      )}
    </>
  );

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value));
  }

  function formatedQuery() {
    return `?period.start=${date.toISOString()}&period.start=${date.toISOString()}&shippingMethod.shippingService=INTERNAL&direction=INCOMING&state=PENDING`;
  }
}
