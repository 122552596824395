import { getColorCode } from "../../utils/utils";
import style from "./colorComponent.module.scss";

export default function ColorComponent({
  color,
  className,
}: {
  color: string;
  className?: string;
}) {
  return (
    <div className={`${style["color"]} ${className}`}>
      <div
        className={style["color-box"]}
        style={{
          backgroundColor: `${getColorCode(color)}`,
        }}
      ></div>
      <span>{color}</span>
    </div>
  );
}
