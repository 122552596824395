import { useState } from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteCart } from "../../requests/professionalCarts";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import Item from "./item/item";
import { fetchCustomersList } from "../../requests/customer";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "Tous",
};

const CUSTOMER_SEARCH_OPTIONS = [
  "phone",
  "familyname",
  "givenname",
  "organization",
  "email",
];

let controller = new AbortController();

export default function Customers() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "customer");
  const { data, isLoading, error, refetch } = useQuery(
    ["customers", query],
    () => fetchCustomersList(formatedQuery()),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Comptes clients</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <Button>+ Ajouter nouveau</Button>
        </Link>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={CUSTOMER_SEARCH_OPTIONS}
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Nom complet</div>

          <div className={style["item-element"]}>Email</div>
          <div className={style["item-element"]}>Téléphone</div>
          <div className={style["item-element"]}>Adresse</div>
          <div className={style["item-element"]}>Verifié</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        {data &&
          data.customers &&
          data.customers.map((value: any, key: number) => {
            return <Item key={key} data={value} onDelete={handleDelete} />;
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(id?: string) {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer ce compte client ?")
    ) {
      controller.abort();
      try {
        controller = new AbortController();
        await deleteCart(id, controller.signal);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleQueryDateChange({ target }: { target: HTMLInputElement }) {
    target.name === "dateFrom"
      ? setQuery({
          ...query,
          dateFrom: `${target.value}`,
          dateTo: query.dateTo !== "" ? query.dateTo : `${target.value}`,
        })
      : setQuery({
          ...query,
          dateTo: `${target.value}`,
          dateFrom: query.dateFrom !== "" ? query.dateFrom : `${target.value}`,
        });
  }

  // function handleQueryChange({
  //   target,
  // }: {
  //   target: HTMLInputElement | HTMLSelectElement;
  // }) {
  //   setQuery((prev) => ({ ...prev, [target.name]: target.value }));
  // }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }

    return formatedQuery;
  }

  function formatDate(date: string | undefined) {
    return date !== ""
      ? date && new Date(date).toISOString().substring(0, 10)
      : "";
  }
}
