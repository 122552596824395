import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteCart, fetchCartsList } from "../../requests/carts";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import Item from "./item/item";
import { CART_STATE } from "../../utils/booking.init";
import { ICart } from "../../types/booking.type";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import { useAuth } from "../../context/auth.context";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  status: "",
  limit: 10,
  offset: 0,
  state: "CART_VALIDATED",
};

const CART_SEARCH_OPTIONS = [
  "contact.phone",
  "contact.displayname",
  "contact.organization",
  "contact.email",
];

let controller = new AbortController();

export default function Carts() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "cart");
  const { data, isLoading, refetch } = useQuery(
    ["carts", query],
    () => fetchCartsList(formatedQuery()),
    { keepPreviousData: true }
  );
  const { roles } = useAuth();
  

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Commandes</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <Button>+ Ajouter nouveau</Button>
        </Link>
      </div>
      <div className={style["filters-container"]}>
        <div className={style["filters"]}>
          <SearchByKeyComponent
            query={query}
            setQuery={setQuery}
            optionsList={CART_SEARCH_OPTIONS}
          />
          <FilterByStatus
            query={query}
            setQuery={setQuery}
            optionsList={CART_STATE}
            field={"status"}
          />
        </div>
      </div>
      <div className={style["bottom"]}>
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
        <div className={style["item"]}>
          <div className={style["item-element"]}>Coordonnées</div>
          <ListHeaderComponent
            name={"createdAt"}
            title="Date de création"
            headerClassName={style["item-element"]}
            query={query}
            setQuery={setQuery}
          />
          <div className={style["item-element"]}>Articles</div>
          <div className={style["item-element"]}>Status</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        {data &&
          data.cartsList &&
          data.cartsList.map((cart: ICart, index: number) => {
            if (roles && roles.includes("ROLE_ADMIN")) {
              return (
                <Item
                  key={index}
                  data={cart}
                  onDelete={handleDelete}
                  zIndex={query.limit - index}
                />
              );
            }
            if (cart.state !== "CART_PENDING") {
              return (
                <Item
                  key={index}
                  data={cart}
                  onDelete={handleDelete}
                  zIndex={query.limit - index}
                />
              );
            }
          })}
        <PagingComponent
          query={query}
          setQuery={setQuery}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );

  async function handleDelete(id?: string) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?")) {
      controller.abort();
      try {
        controller = new AbortController();
        await deleteCart(id, controller.signal);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=desc`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&state=${query.state}`;
    }

    return formatedQuery;
  }

  function formatDate(date: string | undefined) {
    return date !== ""
      ? date && new Date(date).toISOString().substring(0, 10)
      : "";
  }
}
