import { SaveOutlined } from "@mui/icons-material";
import clsx from "clsx";
import styles from "./saveWidget.module.scss";
import { useState } from "react";
import PictureLoadingSpinner from "../../../../../../components/loadingSpinner/pictureloadingSpinner";
import ErrorIcon from "./ErrorIcon/ErrorIcon";

interface SaveWidgetProps {
  className?: string;
  isResponsive?: boolean;
  error?: string[];
  onClick: () => void;
  disabled: boolean;
  isLoading?: boolean;
}

export function SaveWidget({
  isResponsive = false,
  onClick,
  error,
  disabled = false,
  isLoading,
  className,
}: SaveWidgetProps) {
  return (
    <>
      <div
        className={clsx(
          styles["product-widget"],
          styles["save-widget"],
          isResponsive ? styles["responsive-mobile"] : styles["responsive"],
          {
            [styles["disabled"]]: disabled,
          }
        )}
        onClick={() => !disabled && onClick()}
      >
        {isLoading ? (
          <PictureLoadingSpinner />
        ) : (
          <SaveOutlined className={styles["save-icon"]} />
        )}
      </div>
      <ErrorIcon isResponsive={isResponsive} error={error} />
    </>
  );
}
