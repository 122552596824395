import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronRight, Icon } from "react-bootstrap-icons";
import style from "../sideBar.module.scss";
import { MenuItem } from "../sideBar.component";
import { useHideSideBar } from "../../../context/hideSideBar.context";

export const INITIAL_EXPANDABLE: MenuItem[] = [
  { key: 0, expand: false },
  { key: 1, expand: false },
  { key: 2, expand: false },
];

export default function SideBarList({
  title,
  Logo,
  children,
  focused,
  setFocused,
  expandMenu,
}: {
  title: string;
  Logo:
    | Icon
    | (OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
        muiName: string;
      });
  expandMenu?: boolean;
  focused: string;
  setFocused: (value: string) => void;
  children?: ReactElement;
}) {
  const { hideSide, setHideSide } = useHideSideBar();
  const ref = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showTitle, setShowTitle] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (hideSide && ref.current && !ref.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (focused !== title) {
      setShowMenu(false);
    }
  }, [focused]);

  useEffect(() => {
    if (hideSide) {
      setShowMenu(false);
    }
  }, [hideSide]);

  function handleMouseOver() {
    if (hideSide) {
      setShowTitle(!showTitle);
    }
  }

  function handleMouseLeave() {
    setShowTitle(false);
  }

  return (
    <div
      className={style["side-bar-list"]}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`${style["expand-comp"]} ${
          focused === title ? `${style["focused"]}` : ""
        }`}
        onClick={handleExpand}
      >
        <div className={style["title"]}>
          <div className={style["menu-logo-container"]}>
            <Logo className={style["menu-logo"]} />
          </div>
          <div className={`${hideSide ? style["list-hidden"] : ""}`}>
            {title}
          </div>
        </div>
        <div
          className={`${style["chevron"]} ${
            hideSide ? style["list-hidden"] : ""
          }`}
        >
          {expandMenu !== undefined &&
            (showMenu ? <ChevronDown /> : <ChevronRight />)}
        </div>
      </div>
      {showMenu && focused === title && <div ref={ref}>{children}</div>}
      {showTitle && (
        <div className={style["hover-title-container"]}>
          <div className={style["hover-title"]}>{title}</div>
        </div>
      )}
    </div>
  );

  function handleExpand() {
    setShowTitle(false);
    setFocused(title);
    setShowMenu(!showMenu);
    if (hideSide) {
      setShowMenu(true);
      setHideSide(false);
    }
  }
}
