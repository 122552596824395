import style from "./shippingMiniature.module.scss";
import logo from "../../../assets/logo-chronopost.png";
import logoGp from "../../../assets/favicon-32x32.png";
import { IShipping } from "../../../types/tracker.types";
import { ChevronRight } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { IContact } from "../../../types/logistic.types";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { useModalComponent } from "../../../context/modalComponent.context";
import ShippingReadModal from "../../modals/shippingReadModal/shippingReadModal";
import { useHideSideBar } from "../../../context/hideSideBar.context";

export default function ShippingMiniature({
  trackerId,
  isCollect,
  data,
  contact,
}: {
  trackerId: string;
  isCollect: boolean;
  data: IShipping;
  contact: IContact;
}) {
  const navigate = useNavigate();

  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { setHideSide } = useHideSideBar();

  function openShippingModal() {
    if (data && data.documentId.toString() !== "") {
      setModalIsOpen(true);
      setModalChildren(<ShippingReadModal id={data.documentId} />);
      // setHideSide(true);
    }
  }

  return (
    <div
      className={style["shipping-miniature-container"]}
      onClick={openShippingModal}
    >
      {data && data.documentId.toString() !== "" ? (
        <>
          <div className={style["top-container"]}>
            <div className={style["header-container"]}>
              <div className={style["title"]}>
                {isCollect ? "Collecte" : "Livraison"}
              </div>
              <div className={style["top-right-container"]}>
                <div
                  className={style["state"]}
                  style={{ backgroundColor: `${getStatusColor(data.state)}` }}
                >
                  {translate(data.state)}
                  {/* {isCollect ? "Terminé" : "En attente"} */}
                </div>
                {data.shippingService === "CHRONOPOST-2SHOP" ||
                data.shippingService === "CHRONOPOST-EUR" ? (
                  <img
                    className={style["service-logo"]}
                    src={logo}
                    alt={"chronopost-logo"}
                  />
                ) : (
                  <img
                    className={style["service-logo"]}
                    src={logoGp}
                    alt={"chronopost-logo"}
                  />
                )}
              </div>
            </div>
            <>
              {data.shippingService === "CHRONOPOST-2SHOP" ||
              data.shippingService === "CHRONOPOST-EUR" ? (
                <div className={style["body-container"]}>
                  <div className={style["info-line"]}>
                    <span>Date de {isCollect ? "collecte" : "livraison"}</span>
                    <span>
                      {data.period.start
                        ? new Date(data.period.start).toLocaleDateString(
                            "fr-FR"
                          )
                        : "indisponible"}
                    </span>
                  </div>
                  <div className={style["info-line"]}>
                    <span>Numéro de suivi</span>
                    <span>
                      {/* <Link
                         
                        to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${data.externalId}&langue=fr`}
                      > */}
                      {data.externalId}
                      {/* </Link> */}
                    </span>
                  </div>
                </div>
              ) : (
                <div className={style["body-container"]}>
                  <div className={style["info-line"]}>
                    <span>Date de livraison</span>
                    <span>
                      {new Date(data.period.start).toLocaleDateString("fr-FR")}
                    </span>
                  </div>
                  <div className={style["info-line"]}>
                    <span>Période</span>
                    <span>
                      {new Date(data.period.start).toLocaleTimeString("fr-FR", {
                        hour: "numeric",
                        minute: "numeric",
                      }) +
                        " - " +
                        new Date(data.period.end).toLocaleTimeString("fr-FR", {
                          hour: "numeric",
                          minute: "numeric",
                        })}
                    </span>
                  </div>
                </div>
              )}
            </>
          </div>
        </>
      ) : (
        <div className={style["add-new-container"]}>
          <button className={style["add-new"]} onClick={generateShipping}>
            Générer un document {isCollect ? "collecte" : "livraison"}
          </button>
        </div>
      )}
    </div>
  );

  function generateShipping() {
    navigate(
      `/shipping/generate/${trackerId}/${isCollect ? "incoming" : "outgoing"}`
    );
  }
}
