import style from "./item.module.scss";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { ICart } from "../../../types/booking.type";
import { useContext } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import CartModal from "../../../components/modals/cartModal/cartModal";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";

export default function Item({
  data,
  zIndex,
  onDelete,
}: {
  data: ICart;
  zIndex: number;
  onDelete: (id?: string) => void;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: openCartModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/carts/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  return (
    <div className={style["item"]}>
      <div className={`${style["item-element"]} ${style["coordinates"]}`}>
        <span>{data.contact?.displayname}</span>
        <span>{data.contact?.phone}</span>
      </div>
      <div className={style["item-element"]}>
        {data.createdAt && new Date(data.createdAt).toLocaleDateString()}
      </div>
      <div className={style["item-element"]}>{data.productsList.length}</div>
      <div className={style["item-element"]}>
        <div
          className={style["state-container"]}
          style={{ backgroundColor: getStatusColor(data.state) }}
        >
          {data.state && translate(data.state)}
        </div>
      </div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <MoreTools options={MORE_TOOLS_OPTIONS} zIndex={zIndex} />
      </div>
    </div>
  );

  function openCartModal() {
    setModalIsOpen(true);
    data.id && setModalChildren(<CartModal id={data.id} />);
    // setHideSide(true);
  }
}
