import styles from "./item.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import getStatusColor from "../../../translation/statusColor";
import { IEstimate } from "../../../types/booking.type";
import { IProduct } from "../../../types/logistic.types";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import translate from "../../../translation";
import { useModalComponent } from "../../../context/modalComponent.context";
import EstimateModal from "../../../components/modals/estimateModal/estimateModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/StateItem/State";
import ProductsCell from "./ProductsCell";
import clsx from "clsx";

export default function Item({
  data,
  index,
  onDelete,
}: {
  data: IEstimate;
  index: number;
  onDelete: (id?: string) => void;
}) {
  const [hover, setHover] = useState<Boolean>(false);
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {
        openEstimateModal();
      },
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/estimate/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  return (
    <tr
      className={styles["item"]}
      style={{
        backgroundColor: `${hover ? "#e4e4e7" : ""}`,
      }}
    >
      <td
        className={`${styles["item-cell"]} ${styles["item-date"]}`}
        title={`${new Date(data?.createdAt).toLocaleString()}`}
      >
        {new Date(data?.createdAt).toLocaleDateString("fr-FR")}
      </td>
      <td className={styles["item-cell"]}>
        <div className={styles["item-coord"]}>
          <span className={styles["displayname"]}>
            {data.contact.displayname}
          </span>
          <span>{data.contact.organization}</span>
          <span>{data.contact.phone}</span>
          <span>{data.contact.email}</span>
        </div>
      </td>
      <td
        className={clsx(styles["item-cell"], styles["product-cell"])}
        onClick={openEstimateModal}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ProductsCell productsList={data?.productsList} />
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <State state={data.state} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  function openEstimateModal() {
    if (data && data.id !== "") {
      setModalIsOpen(true);
      data.id && setModalChildren(<EstimateModal id={data.id} />);
    }
  }
}
