import { Edit } from "@mui/icons-material";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { ILogistic } from "../../../../types/logistic.types";
import MoreTools, { IMoreToolsOptions } from "../../../MoreTools/MoreTools";
import { StatusItem } from "../../../statusItem/statusItem";
import style from "./logisticModalHeader.module.scss";
import { useNavigate } from "react-router-dom";

interface LogisticModalHeaderProps {
  data?: ILogistic;
  requiredActions: boolean;
  tabSelected: "read" | "action";
  setTabSelected: (value: "read" | "action") => void;
}

export default function LogisticModalHeader({
  data,
  requiredActions,
  tabSelected,
  setTabSelected,
}: LogisticModalHeaderProps) {
  const { setModalIsOpen } = useModalComponent();
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Edit />,
      name: "Modifier",
      onClick: () => handleEdit(),
      to: `/logistic/edit/${data?.id}`,
    },
  ];

  return (
    <div className={style["top-section"]}>
      <div className={style["tabs-section"]}>
        <div
          className={`${style["tab-item"]} ${
            tabSelected === "read" && style["tab-selected"]
          }`}
          onClick={() => setTabSelected("read")}
        >
          Document Logistique
        </div>
        {requiredActions && (
          <div
            className={`${style["tab-item"]} ${
              tabSelected === "action" && style["tab-selected"]
            }`}
            onClick={() => setTabSelected("action")}
          >
            Actions requises
          </div>
        )}
      </div>
      <div className={style["top-section-right"]}>
        <div className={style["state-container"]}>
          {data && (
            <StatusItem data={{ state: data.status, documentId: data.id }} />
          )}
        </div>
        <MoreTools options={MORE_TOOLS_OPTIONS} />
      </div>
    </div>
  );

  function handleEdit() {
    if (!data) {
      return;
    }
    setModalIsOpen(false);
    window.scrollTo(0, 0);
  }
}
