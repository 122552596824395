import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import Operations from ".";
import Create, {
  loader as createLoader,
  // action as createAction,
} from "./create/create";
import Edit, { loader as editLoader } from "./edit/edit";
import { authloader } from "..";

export const route: RouteObject = {
  path: "/operation",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      path: "/operation",
      element: <Operations />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: createLoader,
    },
    {
      path: "edit/:operationId",
      loader: editLoader,
      element: <Edit />,
    },
  ],
};
