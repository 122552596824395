import style from "./modalCrossSellMiniature.module.scss";
import { ICrossSellLogistic } from "../../../../../../types/logistic.types";
import WorkshopId from "../../../../../workshopId/workshopId";

interface CrossSellMiniatureProps {
  className?: string;
  crossSell?: ICrossSellLogistic;
  handleOnClick: () => void;
}

export default function CrossSellMiniature({
  className,
  crossSell,
  handleOnClick,
}: CrossSellMiniatureProps) {
  return (
    <div
      onClick={handleOnClick}
      className={`${style["cross-sell-miniature"]} ${
        className ? className : ""
      } `}
    >
      <div className={style["cross-sell-miniature-left"]}>
        <div className={style["title"]}>Articles cross-selling</div>
        <WorkshopId
          workshopId={crossSell?.workshopId || ""}
          size={"x-small"}
          type={"cross-sell"}
        />
      </div>
      <div className={style["cross-sell-number"]}>
        {crossSell?.crossSellItems?.length || 0}
      </div>
    </div>
  );
}
