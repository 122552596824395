import style from "./logisticReadSection.module.scss";
import { useEffect, useState } from "react";
import {
  ICrossSellItem,
  ICrossSellLogistic,
  ILogistic,
  IProduct,
} from "../../../../types/logistic.types";
import ModalDetailedProduct from "./ModalDetailedProduct/ModalDetailedProduct";
import { ModalProductMiniature } from "../ModalProductMiniature/ModalProductMiniature";
import ModalCrossSellProductList from "./ModalCrossSellProductList/ModalCrossSellProductList";
import WorkshopId from "../../../workshopId/workshopId";
import CrossSellMiniature from "./ModalCrossSellProductList/ModalCrossSellMiniature/ModalCrossSellMiniature";

interface LogisticReadSectionProps {
  data: ILogistic;
  productIndex?: number;
}

export default function LogisticReadSection({
  data,
  productIndex,
}: LogisticReadSectionProps) {
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(
    data?.productsList[productIndex || 0]
  );
  const [selectCrossSell, setSelectCrossSell] = useState<boolean>(false);
  const HAS_CROSS_SELL_ITEMS =
    data.crossSell &&
    data.crossSell.crossSellItems &&
    data.crossSell.crossSellItems?.length > 0;

  return (
    <div className={style["body-section"]}>
      <div className={style["product-list"]}>
        {data.productsList.map((product: IProduct, index: number) => {
          return (
            <ModalProductMiniature
              key={index}
              className={`${style["product-miniature-container"]} ${
                product.id === selectedProduct?.id && style["selected-product"]
              } `}
              product={product}
              handleOnClick={() => handleSelectProduct(product)}
            />
          );
        })}
        {HAS_CROSS_SELL_ITEMS && (
          <CrossSellMiniature
            className={`${style["product-miniature-container"]} ${
              selectCrossSell ? style["selected-product"] : ""
            } `}
            crossSell={data.crossSell}
            handleOnClick={handleSelectCrossSell}
          />
        )}
      </div>
      <div className={style["product-detailed-info"]}>
        {selectedProduct && <ModalDetailedProduct product={selectedProduct} />}
        {selectCrossSell && HAS_CROSS_SELL_ITEMS && (
          <ModalCrossSellProductList crossSell={data.crossSell} />
        )}
      </div>
    </div>
  );

  function handleSelectCrossSell() {
    setSelectCrossSell(true);
    setSelectedProduct(undefined);
  }

  function handleSelectProduct(product: IProduct) {
    setSelectCrossSell(false);
    setSelectedProduct(product);
  }
}
