import style from "./externalShippingModal.module.scss";
import { useContext, useState } from "react";
import translate from "../../../../translation";
import { IProduct } from "../../../../types/logistic.types";
import getStatusColor from "../../../../translation/statusColor";
import { Tag } from "react-bootstrap-icons";
import { useModalComponent } from "../../../../context/modalComponent.context";
import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import { useHideSideBar } from "../../../../context/hideSideBar.context";

interface ShippingProductStateProps {
  logisticId: string;
  product: IProduct;
  index: number;
}

export default function ShippingProductState({
  logisticId,
  product,
  index,
}: ShippingProductStateProps) {
  const [showProductState, setShowProductState] = useState<boolean>(false);
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { setHideSide } = useHideSideBar();

  async function openLogisticModal() {
    setModalIsOpen(true);
    logisticId &&
      setModalChildren(
        <LogisticModal
          productIndex={index}
          id={logisticId}
          requiredActions={false}
        />
      );
    // setHideSide(true);
  }
  return (
    <div
      className={style["modal-product-state-container"]}
      key={`${index}-${product.workshopId}`}
      onClick={openLogisticModal}
    >
      {product.status && ( //showProductState &&
        <div
          className={style["modal-product-state"]}
          style={{ backgroundColor: `${getStatusColor(product.status)}` }}
        >
          {translate(product.status)}
        </div>
      )}
      <div
        className={style["workshop-id"]}
        onClick={() => setShowProductState(!showProductState)}
      >
        {product.workshopId}
        <Tag />
      </div>
    </div>
  );
}
