import { useEffect, useState } from "react";
import style from "./searchComponent.module.scss";
import { useQuery } from "react-query";
import { fetchLogisticList } from "../../../requests/logistic";
import SearchByWorkshopIdV2 from "../../../components/searchByWorkshopIdV2/searchByWorkshopIdV2";
import ProductsList from "./productsList/ProductsList";
import { useDebounceQuery } from "../../../hooks/useDebounceQuery";
import { queryObject } from "../../../types/query.types";
import { INITIAL_QUERY } from "../../../utils/query.init";

interface SearchComponentProps {
  title: string;
  query: string;
  to: string;
  useTrackId?: boolean;
  B2COnly?: boolean;
}

export default function SearchComponent({
  title,
  query,
  to,
  useTrackId = false,
  B2COnly=false
}: SearchComponentProps) {
  const [queryObject, setQueryObject] = useState<queryObject>({
    ...INITIAL_QUERY,
    workshopId: "",
  });
  const { queryParams } = useDebounceQuery([queryObject], 200);
  const { data, refetch, isLoading, isFetching } = useQuery(
    ["search-component", queryParams],
    () =>
      fetchLogisticList(
        `?limit=${queryObject.limit}&offset=0&productsList.workshopId=${queryObject.workshopId}${query}`
      ),
    {
      select(data) {
        if (!data) {
          return data
        }
        if (!B2COnly) {
          return data;
        }
        console.debug("SELECT filteredbefore ",data.logisticsList)
        let filtered = (data.logisticsList || []).filter(d => d.productsList[0] && d.productsList[0].workshopId && d.productsList[0].workshopId.startsWith("1-1-"))
        console.debug("SELECT filtered ater", filtered)
        return {...data, logisticsList: filtered};
      },
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    setQueryObject((prev) => ({ ...prev, limit: 10 }));
  }, [queryObject.workshopId]);

  return (
    <div className={style["search-component"]}>
      <h1>{title}</h1>
      <div className={style["search-component-body"]}>
        <ProductsList
          isLoading={isLoading}
          isFetching={isFetching}
          data={data?.logisticsList}
          refetch={refetch}
          to={to}
          query={queryObject}
          addLimit={handleAddLimit}
          showMore={(data?.logisticsList.length || 0) < (data?.totalCount || 0)}
          useTrackId={useTrackId}
        />
        <SearchByWorkshopIdV2
          className={style["search-by-workshop-id-input"]}
          workshopId={queryObject.workshopId || ""}
          setWorkshopId={handleSetWorkshopId}
        />
      </div>
    </div>
  );

  function handleSetWorkshopId(value: string) {
    setQueryObject((prev) => ({ ...prev, workshopId: value }));
  }

  function handleAddLimit() {
    setQueryObject((prev) => ({ ...prev, limit: prev.limit + 10 }));
  }
}
