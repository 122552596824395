import { IEvent } from "../../../../types/tracker.types";
import styles from "./overviewHistory.module.scss";

interface OverviewHistoryProps {
  eventsList?: IEvent[];
}

export default function OverviewHistory({ eventsList }: OverviewHistoryProps) {
  if (!eventsList) {
    return <></>;
  }

  return (
    <div className={styles["history-list"]}>
      <div className={styles["header"]}>
        <div className={styles["header-element-container"]}>Date</div>
        <div className={styles["header-element-container"]}>Document</div>
        <div className={styles["header-element-container"]}>Catégorie</div>
        <div className={styles["header-element-container"]}>Message</div>
        <div className={styles["header-element-container"]}>Source</div>
      </div>
      {eventsList.map((event: IEvent, index: number) => {
        return (
          <div className={styles["item"]} key={index}>
            <div className={styles["item-element"]}>
              {new Date(event.date).toLocaleString("fr-FR")}
            </div>
            <div className={styles["item-element"]}>{event.documentName}</div>
            <div className={styles["item-element"]}>{event.category}</div>
            <div className={styles["item-element"]}>{event.description}</div>
            <div className={styles["item-element"]}>{event.service}</div>
          </div>
        );
      })}
    </div>
  );
}
