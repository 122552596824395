import { useMutation, useQuery } from "react-query";
import { fetchLogisticFromTrackerId } from "../../../requests/tracker";
import style from "./refashionImagesUpload.module.scss";
import { IOperation, IQuote } from "../../../types/accounting.types";
import WorkshopId from "../../workshopId/workshopId";
import { useContext, useState } from "react";
import { ICommentObject, IProduct } from "../../../types/logistic.types";
import { COLOR_LIST } from "../../../translation/color";
import {
  ImageApiPath,
  isValidJSONString,
  sortOperations,
} from "../../../utils/utils";
import DEFAULT_PICTURE from "../../../assets/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg";
import { Check } from "@mui/icons-material";
import { registerRepair } from "../../../requests/refashion";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { PRODUCT_CATEGORIES } from "../../../utils/operation.init";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { useModalComponent } from "../../../context/modalComponent.context";
import { useImageViewer } from "../../../context/imageViewer.context";
import { ModalProductMiniature } from "../logisticModal/ModalProductMiniature/ModalProductMiniature";
import Button from "../../Button/Button";

interface RefashionImagesUploadProps {
  quote: IQuote;
  trackId: string;
}

export default function RefashionImagesUpload({
  quote,
  trackId,
}: RefashionImagesUploadProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(
    undefined
  );

  const [refashionProducts, setRefashionProducts] = useState<any[]>(
    (quote.productsList || []).map((p) => ({
      trackId: trackId,
      id: p.productId,
      workshopId: p.workshopId,
      operations: p.operationsList.filter((o) => o.canceled !== true),
      images: [],
      refashion: p.operationsList.some(
        (o) =>
          o.canceled !== true &&
          o.refashionOperation &&
          o.refashionDiscount &&
          o.refashionOperation !== ""
      ),
    }))
  );

  const logisticQuery = useQuery(
    "logisticQuery",
    () => fetchLogisticFromTrackerId({ params: { id: trackId } }),
    {
      onSuccess(data) {
        data?.productsList.forEach((product) => {
          let productIndex = refashionProducts.findIndex(
            (s) => s.id === product?.id
          );
          if (productIndex < 0) {
            return;
          }
          let productsCopy = [...refashionProducts];

          productsCopy[productIndex].category = PRODUCT_CATEGORIES.find(
            (c) => c.description === product.category
          )?.value;
          setRefashionProducts(productsCopy);
        });
      },
    }
  );

  const refashionDeclarationMutation = useMutation(registerRepair, {
    onError: (error) => {
      console.debug("error", error);
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSuccess: (response) => {
      toast.success("Réparations déclarées.", TOAST_SUCCESS_OPTIONS);
      setModalIsOpen(false);
      setModalChildren(<></>);
    },
  });

  function getSelectedImages(workshopId: string): string[] {
    let productIndex = refashionProducts.findIndex(
      (s) => s.workshopId === workshopId
    );
    if (productIndex < 0) {
      return [];
    }
    return refashionProducts[productIndex].images || [];
  }

  function isValidateDisabled() {
    if (refashionProducts.some((p) => p.refashion)) {
      return refashionProducts.some((p) => p.refashion && p.images.length < 1);
    }
    return true;
  }

  function handleImageClick(productId: string, image: string) {
    let productIndex = refashionProducts.findIndex(
      (s) => s.workshopId === selectedProduct?.workshopId
    );
    if (productIndex < 0) {
      return;
    }
    let productsCopy = [...refashionProducts];
    let imageIndex = refashionProducts[productIndex].images.findIndex(
      (i: string) => i === image
    );
    if (imageIndex < 0) {
      productsCopy[productIndex].images.push(image);
      productsCopy[productIndex].category = PRODUCT_CATEGORIES.find(
        (c) => c.description === selectedProduct?.category
      )?.value;
    } else {
      productsCopy[productIndex].images.splice(imageIndex, 1);
      productsCopy[productIndex].category = selectedProduct?.category;
    }
    setRefashionProducts(productsCopy);
  }

  if (logisticQuery.isLoading || refashionDeclarationMutation.isLoading) {
    return (
      <div className={style["container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  return (
    <div className={style["container"]}>
      <div className={style["products-container"]}>
        {(logisticQuery?.data?.productsList || [])?.map((product, index) => (
          <div
            key={product.id}
            onClick={() => setSelectedProduct(product)}
            className={`${style["product-miniature-container"]} ${
              product.id === selectedProduct?.id && style["selected-product"]
            } `}
          >
            <ModalProductMiniature product={product} />
          </div>
        ))}
        <div>
          <Button
            disabled={isValidateDisabled()}
            onClick={() =>
              refashionDeclarationMutation.mutate({
                products: refashionProducts.filter((p) => p.images.length > 0),
                trackId: trackId,
                quoteId: quote.id,
              })
            }
          >
            Valider
          </Button>
        </div>
      </div>
      <div className={style["product-detailed-info"]}>
        {selectedProduct && (
          <ModalDetailedProduct
            product={selectedProduct}
            refashionProduct={refashionProducts.find(
              (p) => p.workshopId === selectedProduct.workshopId
            )}
          />
        )}
        <div className={style["modal-detailed-product"]}>
          <ImagesList
            product={selectedProduct?.id || ""}
            selectedImages={getSelectedImages(
              selectedProduct?.workshopId || ""
            )}
            onClick={handleImageClick}
            incomingPicturesList={selectedProduct?.incomingPicturesList || []}
          />
        </div>
      </div>
    </div>
  );
}

interface ModalProductProps {
  product: IProduct;
  refashionProduct: any;
}

function ModalDetailedProduct({
  product,
  refashionProduct,
}: ModalProductProps) {
  const { setViewerIsOpen, setPictureList, setIndex } = useImageViewer();
  const colorCode = COLOR_LIST.find(
    (value: any) => product.color === value.name
  )?.color;
  const OTHER_ARTICLE: ICommentObject = parseComment();

  return (
    <div className={style["modal-detailed-product"]}>
      <div className={style["left"]}>
        <WorkshopId
          workshopId={product.workshopId}
          className={style["workshop-id-miniature"]}
        />
        <div className={style["operation-list-container"]}>
          <span>
            Liste des opérations éligibles{" ("}
            {
              (refashionProduct?.operations || []).filter(
                (op: any) =>
                  !op.canceled && op.refashionOperation && op.refashionDiscount
              ).length
            }
            {")"}
          </span>
          <ul className={style["operation-list"]}>
            {(refashionProduct?.operations || [])
              .filter(
                (op: any) =>
                  !op.canceled && op.refashionOperation && op.refashionDiscount
              )
              .sort(sortOperations)
              .map((operation: IOperation, index: number) => {
                return (
                  <li
                    className={`${
                      operation.canceled && style["strikethrough"]
                    }`}
                    key={index}
                  >
                    <strong>
                      {operation.refashionOperation}
                      {operation.refashionDiscount} €
                    </strong>
                    <i> {operation.description}</i>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );

  function parseComment() {
    if (product && product.comments) {
      if (!isValidJSONString(product.comments)) {
        return {};
      } else {
        return JSON.parse(product.comments);
      }
    }
  }

  function handlePictureClick(index: number) {
    if (product.incomingPicturesList) {
      setViewerIsOpen(true);
      setIndex(index);

      const formatedPictureList = product.incomingPicturesList.map(
        (element: string) => {
          return `${process.env.REACT_APP_API_URL}/file/${element}`;
        }
      );

      setPictureList(formatedPictureList || DEFAULT_PICTURE);
    }
  }
}

function ImagesList({
  product,
  incomingPicturesList = [],
  onClick,
  selectedImages,
}: {
  product: string;
  incomingPicturesList: string[];
  onClick: (product: string, picture: string) => void;
  selectedImages: string[];
}) {
  return (
    <div className={style["picture-container"]}>
      {incomingPicturesList.map((picture) => (
        <div
          className={`${style["main-picture"]}`}
          onClick={() => onClick(product, picture)}
        >
          <img
            // style={{ display: !isLoading ? "block" : "none" }}
            className={`${
              selectedImages.includes(picture) ? style["picture-selected"] : ""
            }`}
            src={ImageApiPath(picture)}
            alt={"preview"}
          />
          <div
            className={`${style["check-mark-container"]} ${
              selectedImages.includes(picture) ? "" : style["hidden"]
            }`}
          >
            <Check className={`${style["check-mark"]}`} />
          </div>
        </div>
        // <MyImage
        //   className={style["main-picture"]}
        //   src={picture}
        //   index={0}
        //   pictureList={[picture]}
        // />
      ))}
    </div>
  );
}
