import { useState } from "react";
import style from "./sectionFormTemplate.module.scss";

interface SectionFormTemplateProps {
  title: string;
  collapsedDefault?: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function SectionFormTemplate({
  title,
  children,
  className,
  collapsedDefault = false,
}: SectionFormTemplateProps) {
  const [collapsed, setCollapsed] = useState(collapsedDefault)
  return (
    <div className={style["section-form-template"]}>
      <hr className={style["separator"]} />
      <div className={[style["section-form"], collapsed ? style["collapsed"] : ""].join(' ')}>
        <div className={style["collapsible"]} onClick={handleCollapse}>
          {
            collapsed ? "+  " : "-  "
          }
        </div>
        <div className={`${style["left"]} ${className ? className : ""}`}>
          {title}
        </div>
        <div className={style["right"]}>{children}</div>
      </div>
    </div>
  );

  function handleCollapse() {
    setCollapsed(prev => !prev)
  }
}
