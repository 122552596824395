import style from "./logisticActionSection.module.scss";
import {
  ICrossSellLogistic,
  ILogistic,
} from "../../../../types/logistic.types";
import MoveProducts from "./MoveProducts/MoveProducts";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import {
  checkIfWorkshopIdExists,
  updateLogistic,
} from "../../../../requests/logistic";
import Button from "../../../Button/Button";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { useState } from "react";
import PrepareCrossSellProducts from "./PrepareCrossSellProducts/PrepareCrossSellProducts";

interface LogisticActionSectionProps {
  data: ILogistic;
  dueDate?: Date;
}

export default function LogisticActionSection({
  data,
  dueDate,
}: LogisticActionSectionProps) {
  const MOVE_PRODUCT_STEP = 0;
  const CROSS_SELL_STEP = 1;
  const [step, setStep] = useState<number>(MOVE_PRODUCT_STEP);
  const [logistic, setLogistic] = useState<ILogistic>({ ...data });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setModalIsOpen } = useModalComponent();

  return (
    <div className={style["logistic-action-section"]}>
      {step === MOVE_PRODUCT_STEP && (
        <MoveProducts
          productsList={logistic.productsList}
          setProductsList={(value) => {
            setLogistic((prev) => ({ ...prev, productsList: [...value] }));
          }}
          dueDate={dueDate}
        />
      )}
      {step === CROSS_SELL_STEP &&
        logistic.crossSell &&
        logistic.crossSell.crossSellItems &&
        logistic.crossSell.crossSellItems.length > 0 && (
          <PrepareCrossSellProducts
            logistic={logistic}
            setCrossSell={handleSetCrossSell}
          />
        )}
      <div className={style["logistic-action-container-bottom"]}>
        {step > 0 && (
          <Button className={style["previous-button"]} onClick={previousStep}>
            Retour
          </Button>
        )}
        <Button isLoading={isLoading} onClick={handleValidateAction}>
          Valider
        </Button>
      </div>
    </div>
  );

  async function handleValidateAction() {
    setIsLoading(true);
    if (step === MOVE_PRODUCT_STEP) {
      return await handleMoveProductStep();
    }
    if (step === CROSS_SELL_STEP) {
      await handleCrossSellingStep();
    }
  }

  async function handleMoveProductStep() {
    if (!checkIfAllProductsAreInWorkshop()) {
      toast.error(
        "Vous devez déplacer tous les articles avant de pouvoir passer à la prochaine étape.",
        TOAST_ERROR_OPTIONS
      );
      return setIsLoading(false);
    }
    if (
      logistic.crossSell &&
      logistic.crossSell.crossSellItems &&
      logistic.crossSell.crossSellItems.length > 0
    ) {
      setStep(CROSS_SELL_STEP);
      return setIsLoading(false);
    }

    try {
      await updateLogistic({ ...logistic, status: "LOGISTIC_WORKSHOP" });
      setModalIsOpen(false);
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCrossSellingStep() {
    if (
      !logistic.crossSell?.workshopId ||
      logistic.crossSell?.workshopId.length < 7 ||
      (await checkIfWorkshopIdExists(logistic.crossSell?.workshopId))
    ) {
      toast.error(
        "Veuillez rentrer un numéro de ticket valide.",
        TOAST_ERROR_OPTIONS
      );
      return setIsLoading(false);
    }
    try {
      await updateLogistic({
        ...logistic,
        status: "LOGISTIC_WORKSHOP",
      });
      setModalIsOpen(false);
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  function checkIfAllProductsAreInWorkshop(): boolean {
    return logistic.productsList.every(
      (product) => product.status !== "PENDING_SHELVED"
    );
  }

  function previousStep() {
    setStep((prev) => prev - 1);
  }

  function handleSetCrossSell(crossSell: ICrossSellLogistic) {
    setLogistic((prev) => ({ ...prev, crossSell }));
  }
}
