import style from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Check, Close, Delete, Edit, Launch } from "@mui/icons-material";
import { Link } from "react-router-dom";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { ICart } from "../../../types/booking.type";
import { IAddress, ICustomer } from "../../../types/customer.type";

export default function Item({
  data,
  onDelete,
}: {
  data: ICustomer;
  onDelete: (id?: string) => void;
}) {
  return (
    <div className={style["item"]}>
      <div className={`${style["item-element"]} ${style["coordinates"]}`}>
        <span>
          {data.familyname} {data.givenname}
        </span>
      </div>
      <div className={style["item-element"]}>{data.email}</div>
      <div className={style["item-element"]}>{data.phone}</div>
      <div className={style["item-element"]}>
        <div className={style["address-list"]}>
          {data.addresses?.map((address: IAddress, key: number) => {
            return (
              <div className={style["address"]} key={key}>
                <div>
                  {address.address1} {address.address2}
                  {","} {address.zipCode} {address.city}
                  {","} {address.country}
                </div>
                <div></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={style["item-element"]}>
        {data.verified ? <Check /> : <Close />}
      </div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        {data.trackers && data.trackers[0] && (
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["mylink"]}
            to={`/overview/${data.trackers[0]}`}
            title={"ouvrir"}
          >
            <Launch className={style["icon"]} />
          </Link>
        )}
        <Link
          onClick={() => window.scrollTo(0, 0)}
          className={style["mylink"]}
          to={`/customers/edit/${data.id}`}
          title="modifier"
        >
          <Edit className={style["icon"]} />
        </Link>
        {/* <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Delete
            className={`${style["icon"]} ${style["delete-icon"]}`}
            onClick={() => onDelete(data.id)}
          />
        </ProtectedComponent> */}
      </div>
    </div>
  );
}
