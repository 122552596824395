import { useState } from "react";
import { IProduct } from "../../types/logistic.types";
import Button from "../Button/Button";
import styles from "./printLabel.module.scss";
import ModalPortal from "../modals/ModalPortal";
import ReadModal from "../modals/ReadModal/ReadModal";
import PrintProductLabelModal from "../modals/printProductLabelModal/PrintProductLabelModal";
import { PrintOutlined } from "@mui/icons-material";

interface PrintLabelButtonProps {
  product: IProduct;
}

export default function PrintLabelButton({ product }: PrintLabelButtonProps) {
  const [printLabelModal, setPrintLabelModal] = useState<boolean>(false);
  return (
    <>
      <Button onClick={handleOpenPrintLabelModal}>
        <PrintOutlined className={styles["icon"]} /> Imprimer ticket
      </Button>
      <ModalPortal isOpen={printLabelModal}>
        <ReadModal handleCloseModal={handleClosePrintLabelModal}>
          <PrintProductLabelModal product={product} />
        </ReadModal>
      </ModalPortal>
    </>
  );

  function handleClosePrintLabelModal() {
    setPrintLabelModal(false);
  }

  function handleOpenPrintLabelModal() {
    setPrintLabelModal(!printLabelModal);
  }
}
