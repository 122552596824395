import style from "./modalCrossSellProductList.module.scss";
import {
  ICrossSellItem,
  ICrossSellLogistic,
} from "../../../../../types/logistic.types";
import WorkshopId from "../../../../workshopId/workshopId";
import ColorComponent from "../../../../colorComponent/colorComponent";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";

interface ModalCrossSellProps {
  crossSell?: ICrossSellLogistic;
}

export default function ModalCrossSellProductList({
  crossSell,
}: ModalCrossSellProps) {
  return (
    <div className={style["modal-detailed-cross-sell"]}>
      <WorkshopId
        workshopId={crossSell?.workshopId}
        size="large"
        type={"cross-sell"}
      />
      <div className={style["title"]}>Liste des articles</div>
      <div className={style["cross-sell-list"]}>
        {crossSell?.crossSellItems?.map((cs: ICrossSellItem, index: number) => {
          return (
            <div key={index} className={style["cross-sell-item"]}>
              <div className={style["description"]}>
                {cs.description}
                {cs.color && <ColorComponent color={cs.color} />}
              </div>
              <div className={style["cart-quantity"]}>{cs.cartQuantity}</div>
              <div className={style["state"]}>
                {cs?.isItemReady ? (
                  <CheckCircleOutline className={style["ready-icon"]} />
                ) : (
                  <CancelOutlined className={style["not-ready-icon"]} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
