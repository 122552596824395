import { useNavigate } from "react-router-dom";
import { ITracker } from "../../../types/tracker.types";
import style from "./item.module.scss";
import { StatusItem } from "../../../components/statusItem/statusItem";
import ProgressBar from "../../../components/progressBar/progressBar";
import { Link } from "react-router-dom";
import logoChrono from "../../../assets/logo-chronopost-small.png";
import logoGP from "../../../assets/favicon-32x32.png";

export default function Item({ data }: { data: ITracker }) {
  const navigate = useNavigate();

  return (
    <Link
      to={`/overview/${data.id}`}
      className={style["overview-item-container"]}

      // onClick={navigateToOverviewPage}
    >
      <div className={style["item-element"]}>{data.contact?.displayname}</div>
      {/* <div className={style["item-element"]}>
        <ProgressBar data={data} />
      </div> */}
      <StatusItem data={data.estimate} />
      <StatusItem data={data.cart} />
      <StatusItem
        data={data.incomingShipping}
        image={
          data.incomingShipping?.shippingService?.includes("CHRONO")
            ? logoChrono
            : logoGP
        }
      />
      <StatusItem data={data.logistic} />
      <StatusItem data={data.quote} />
      <StatusItem
        data={data.outgoingShipping}
        image={
          data.outgoingShipping?.shippingService?.includes("CHRONO")
            ? logoChrono
            : logoGP
        }
      />
      <StatusItem data={data} isGeneral />
    </Link>
  );

  // function navigateToOverviewPage() {
  //   navigate(`/overview/${data.id}`);
  // }
}
