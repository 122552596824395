import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./create.module.scss";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import { IContact, IProduct } from "../../../types/logistic.types";
import { IValidationError } from "../../../types";
import { ICart } from "../../../types/booking.type";
import { INITIALE_CART } from "../../../utils/booking.init";
import { addCart, updateCart } from "../../../requests/carts";
import { addCustomer, linkTracker } from "../../../requests/customer";
import SelectState from "../../shipping/create/SelectState/SelectState";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConcurrentTasksTemplate from "../../../components/concurrentTasksTemplate/ConcurrentTasksTemplate";
import CheckboxItem from "../../../components/checkboxItem/CheckboxItem";
import ProductSection from "../../../components/forms/productForm/ProductSection";
import OverviewButton from "../../../components/OverviewButton/OverviewButton";

export default function Create({ data }: { data?: ICart }) {
  const [formValues, setFormValues] = useState<ICart>(
    data ?  {...INITIALE_CART, ...data} : { ...INITIALE_CART }
  );
  console.debug("data", data)
  console.debug("formvalues", formValues)
  const [addOrUpdateQuote, setAddOrUpdateQuote] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  return (
    <div className={style["container"]}>
      <div ref={ref} className={style["title"]}>
        <span className={style["title-container"]}>
          {data ? "Modifier commande" : "Nouvelle commande"}
          <OverviewButton trackId={data?.trackId} />
        </span>
        <SelectState
          label="Status de la commande"
          state={formValues.state}
          setState={(state) => setFormValues((prev) => ({ ...prev, state }))}
          stateOption={["CART_PENDING", "CART_VALIDATED", "CART_CANCELED"]}
        />
      </div>
      <div className={style["form"]}>
        <SectionFormTemplate title="Contact">
          <ContactForm
            contact={formValues.contact}
            setContact={handleContactChange}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Commentaire">
          {data?.comments ? (
            <p>"{data?.comments}"</p>
          ) : (
            <textarea
              className={style["comment-input"]}
              name="comments"
              rows={5}
              value={formValues.comments}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, comments: target.value }))
              }
            />
          )}
        </SectionFormTemplate>
        <hr className={style["separator"]} />
        <ProductSection
          productsList={formValues.productsList}
          setProductsList={handleProductChange}
          hasWorkshopId={false}
        />
        <div>
          {validationError.find(
            (value: IValidationError) => value.field === "productsList"
          ) && (
            <div className={style["error-message"]}>
              * veuillez ajouter au moins un article.
            </div>
          )}
        </div>
        <ConcurrentTasksTemplate>
          <CheckboxItem
            checked={addOrUpdateQuote}
            setChecked={setAddOrUpdateQuote}
            title="Mettre à jour le devis associé"
          />
        </ConcurrentTasksTemplate>
        <div className={style["submit-button-container"]}>
          <Button type={"submit"} onClick={handleConfirmSubmit}>
            {data ? "Mettre à jour" : "Créer"}
          </Button>
        </div>
      </div>
    </div>
  );

  async function handleConfirmSubmit() {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={`Voulez-vous ${
          data?.id ? "mettre à jour" : "créer"
        } cette commande ?`}
        element={
          <>
            {addOrUpdateQuote && (
              <ul style={{ fontSize: "14px" }}>
                Ceci entrainera les actions suivantes:
                <li>Mise à jour du devis</li>
              </ul>
            )}
          </>
        }
        confirmText="Oui"
        cancelText="Non"
        onConfirm={handleSubmit}
      />
    );
  }

  async function handleSubmit() {
    try {
      const response = data?.id
        ? await updateCart(formValues, addOrUpdateQuote)
        : await addCart(formValues, addOrUpdateQuote);
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        return;
      }
      toast.success(
        `Commande ${data ? "mis à jour" : "ajoutée"}.`,
        TOAST_SUCCESS_OPTIONS
      );
      addOrUpdateQuote &&
        toast.success(`Devis mis à jour.`, TOAST_SUCCESS_OPTIONS);
      let customerId = formValues.contact.id;
      if (!customerId) {
        try {
          let response = await addCustomer(formValues.contact);
          customerId = response.id;
          toast.success(
            `Nouveau client [${formValues.contact.email}] ajouté ou mis à jour.`,
            TOAST_SUCCESS_OPTIONS
          );
        } catch (error: any) {
          toast.warning(`Customer: ${error?.message}`, TOAST_ERROR_OPTIONS);
          return navigate("/carts");
        }
      }

      if (response && (response as { cart: ICart }).cart) {
        let cart = (response as { cart: ICart }).cart;
        if (cart.trackId && formValues.contact.id) {
          try {
            await linkTracker(formValues.contact.id, cart.trackId);
            toast.success("Tracker lié avec succès.", TOAST_SUCCESS_OPTIONS);
          } catch (error: any) {
            toast.warning(
              `Tracker link: ${error?.message}`,
              TOAST_ERROR_OPTIONS
            );
            return navigate("/carts");
          }
        }
      }
      navigate("/carts");
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({ ...prev, contact }));
  }

  function handleProductChange(productsList: IProduct[]) {
    setFormValues((prev) => ({ ...prev, productsList }));
  }
}
