import { useState } from "react";
import styles from "./incomingShipping.module.scss";
import ShippingService from "./ShippingService/ShippingService";
import ShippingServiceButton from "./ShippingService/ShippingServiceButton/ShippingServiceButton";

export default function IncomingShipping() {
  const [shippingService, setShippingService] = useState<string>("CHRONO");

  return (
    <div className={styles["incoming-shipping-container"]}>
      <h1>Réceptionner un colis</h1>
      <div className={styles["shipping-service-container"]}>
        <ShippingServiceButton
          name={"Chronopost"}
          isSelected={shippingService === "CHRONOPOST-2SHOP"}
          shippingService={"CHRONOPOST-2SHOP"}
          handleSelectShippingService={handleSelectShippingService}
        />
        <ShippingServiceButton
          name={"Chronopost Europe"}
          isSelected={shippingService === "CHRONOPOST-EUR"}
          shippingService={"CHRONOPOST-EUR"}
          handleSelectShippingService={handleSelectShippingService}
        />
        <ShippingServiceButton
          name={"Collecte G&P"}
          isSelected={shippingService === "GP"}
          shippingService={"GP"}
          handleSelectShippingService={handleSelectShippingService}
        />
        <ShippingServiceButton
          name={"Dépôt et retrait boutique"}
          isSelected={shippingService === "DROPANDCOLLECT"}
          shippingService={"DROPANDCOLLECT"}
          handleSelectShippingService={handleSelectShippingService}
        />
      </div>
      <div className={styles["incoming-shipping-bottom"]}>
        <ShippingService shippingService={shippingService} />
      </div>
    </div>
  );

  function handleSelectShippingService(name: string) {
    setShippingService(name);
  }
}
